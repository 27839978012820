import { ValidationError } from 'joi';

export const PRICE_BY_SKU_ROUTE = '/api-offers/price-range/sku';
export const PRICE_LIST_ROUTE = '/api-offers/price-list';
export const CUSTOMER_API_ROUTE = '/data-customer';
export const ISO_DATE_PATTERN = /\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d\.\d+[A-Z]/;

export const CUSTOMER_V3_API_PATH = `https://${process.env.V3_CUSTOMER_API_STAGE}/v2/customers`;

export default function getValidationErrors(err: ValidationError): string {
  const validationErrors = [];

  err.details.forEach((error) => {
    const messageDetails = error.message.split('"')[2];

    validationErrors.push(`${error.path[1]}${messageDetails}`);
  });
  return validationErrors.join(', ');
}

export function objectToQueryParams(params: { [key: string]: any }): string {
  const createStringParamsArray = Object.keys(params).map((key: string): string => {
    if (Array.isArray(params[key])) {
      return `${key}=${(params[key] as Array<string>).join(',')}`;
    }
    if (params[key] !== '') {
      return `${key}=${String(params[key])}`;
    }
    return null;
  });

  return createStringParamsArray.filter(f => f).join('&');
}
