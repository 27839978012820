import { SalesDashboardApiAuthResponseDto } from '@types';
import salesDashboardApi from 'src/services/saleDashboardApi';

// eslint-disable-next-line import/prefer-default-export
export async function getAuthAndCharts(): Promise<SalesDashboardApiAuthResponseDto> {
  const {
    data,
  }: { data: SalesDashboardApiAuthResponseDto } = await salesDashboardApi.post(
    '/users/self/actions/exchange-token',
  );
  return data;
}
