import { AxiosResponse } from 'axios';
import {
  AddSku,
  AddSkuRequest,
  CreateSkuPriceRequest,
  CreateSkuPriceResponse,
  DeletedPriceList,
  GetPriceBySkuRequest,
  GetPriceBySkuResponse,
  PriceListRequest,
  PriceListResponse,
  PriceListSearchRequest,
  PriceListSearchResponse,
  CreatePriceListRequest,
  CreatePriceListResponse,
  CreatePriceRangeRequest,
  CreatePriceRangeResponse,
  UpdatePriceListRequest,
  GetAddonsRequest,
  GetAddonsResponse,
  PriceList,
  UpdatePriceRangeResponse,
  CreateUpdatePriceRangeRequestOffer,
} from '../../../../@types';
import api from '../../../services/api';
import {
  validateCreatePriceList,
  validateGetPriceBySkuRequest,
  validateUpdatePriceList,
} from '../validators';
import { PRICE_BY_SKU_ROUTE } from '../../../lib';

export async function fetchAllPriceListsService(
  params: PriceListRequest,
): Promise<PriceListResponse> {
  const {
    data: { data, query },
  } = await api.get<PriceListResponse>('/api-offers/price-list', {
    params: {
      limit: params?.limit ?? 20,
      offset: params?.offset ?? 0,
    },
  });
  return { data, query };
}
export async function deletePriceListService(
  priceListId: string,
): Promise<DeletedPriceList> {
  const { data } = await api.delete<DeletedPriceList>(
    `/api-offers/price-list/${priceListId}`,
  );
  return data;
}

export async function createSkuPriceService(
  request: CreateSkuPriceRequest,
): Promise<CreateSkuPriceResponse> {
  const { data } = await api.post<CreateSkuPriceRequest[]>(
    '/api-offers/price',
    {
      request,
    },
  );

  return {
    data,
  };
}

export async function addSKUService(request: AddSkuRequest): Promise<AddSku> {
  const { data } = await api.post<AddSku>('/api-offers/price-range/pim', {
    sku: request,
  });
  return data;
}

export async function createPriceListService(
  request: CreatePriceListRequest,
): Promise<CreatePriceListResponse> {
  validateCreatePriceList(request);
  const { data } = await api.post<CreatePriceListResponse>(
    '/api-offers/price-list',
    request,
  );
  return data;
}

export async function updatePriceListService(
  request: UpdatePriceListRequest,
  priceListId: number,
): Promise<PriceList> {
  validateUpdatePriceList(request);
  const { data } = await api.put<PriceList>(
    `/api-offers/price-list/${priceListId}`,
    request,
  );
  return data;
}

export async function getPriceBySkuService(
  request: GetPriceBySkuRequest,
): Promise<GetPriceBySkuResponse> {
  validateGetPriceBySkuRequest(request);

  const getPriceBySkuResponse = api.post<GetPriceBySkuResponse>(
    PRICE_BY_SKU_ROUTE,
    request,
  );
  const { data: fetchedData } = await getPriceBySkuResponse;

  return fetchedData;
}

export async function searchPriceListService(
  request: PriceListSearchRequest,
): Promise<PriceListSearchResponse> {
  const response: AxiosResponse = await api.post(
    '/api-offers/price-range/search',
    {
      priceListId: request.priceListId,
      keyword: request.keyword,
    },
    {
      params: {
        offset: request.offset,
        limit: request.limit,
      },
    },
  );
  return {
    query: response.data.query,
    results: response.data.results,
  };
}

export async function createPriceRangeService(
  request: CreatePriceRangeRequest,
): Promise<CreatePriceRangeResponse> {
  const { data } = await api.post<CreatePriceRangeResponse>(
    '/api-offers/price-range/set',
    request,
  );
  return data;
}

export async function updatePriceRangeService(
  request: CreateUpdatePriceRangeRequestOffer,
  priceListId: number,
  itemSku: string,
): Promise<UpdatePriceRangeResponse> {
  const { data } = await api.put<UpdatePriceRangeResponse>(
    '/api-offers/price-range/set',
    request,
    {
      params: {
        priceListId,
        itemSku,
      },
    },
  );
  return data;
}

export async function fetchCurrencyCodesService(): Promise<any> {
  const { data: channels } = await api.get<any>('/api-oms/warehouse/channels');

  const response = await Promise.all(
    channels.map(async (channel) => {
      const result = await api.get('/api-offers/get-currency', {
        headers: {
          'x-site-context': JSON.stringify({ channel: channel.channelId }),
        },
      });
      return result.data;
    }),
  );
  return response;
}

export async function getAddonsService(
  params: GetAddonsRequest,
): Promise<GetAddonsResponse> {
  const { data } = await api.get<GetAddonsResponse>('/api-offers/addon', {
    params,
  });
  return data;
}
