import {
  ListOrganizationAddressResponse,
  ListOrganizationAddressRequest,
  OrganizationAddress,
  OrganizationAddressViewRequest,
} from '@types';
import customerAPI from 'src/services/customerApi';
import { CUSTOMER_API_ROUTE } from 'lib/index';

export async function listOrganizationAddressService (
  params: ListOrganizationAddressRequest,
  organizationId: string,
): Promise<ListOrganizationAddressResponse> {
  const { data } = await customerAPI.get<ListOrganizationAddressResponse>(`${CUSTOMER_API_ROUTE}/v1/organizations/${organizationId}/addresses`, {
    params,
  });
  return data;
}

export async function OrganizationAddressCreateService (
  params: OrganizationAddress,
  organizationId: string,
): Promise<OrganizationAddress> {
  const { data } = await customerAPI.post<OrganizationAddress>(`${CUSTOMER_API_ROUTE}/v1/organizations/${organizationId}/addresses`, {
    ...params,
  });
  return data;
}

export async function OrganizationAddressViewService (params: OrganizationAddressViewRequest):
Promise<OrganizationAddress> {
  const { data } = await customerAPI.get<OrganizationAddress>(`${CUSTOMER_API_ROUTE}/v1/organizations/${params.organizationId}/addresses/${params.orgAddrId}`);
  return data;
}

export async function OrganizationAddressEditService (
  params: OrganizationAddress,
  organizationId: string,
  orgAddrId: string,
):
Promise<OrganizationAddress> {
  const { data } = await customerAPI.put<OrganizationAddress>(`${CUSTOMER_API_ROUTE}/v1/organizations/${organizationId}/addresses/${orgAddrId}`, {
    ...params,
  });
  return data;
}
