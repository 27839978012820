import validate from 'validate.js';
import Joi from 'joi';
import {
  Customer,
  CustomerV3,
  ListCustomersRequest,
  ListCustomersResponse,
  SearchCustomerRequest,
  User,
  UserPartyRequest,
} from '@types';
import { ISO_DATE_PATTERN } from 'lib/index';

const customerJoi = Joi.defaults(schema => schema.options({
  allowUnknown: true,
}));

const responseObjectSchema = customerJoi.object({
  _id: Joi.string().required(),
  parentPartyId: Joi.string(),
  internalPartyId: Joi.string(),
  name: Joi.string(),
  partyType: Joi.string(),
  isActive: Joi.boolean(),
  email: Joi.string(),
  additionalAttributes: Joi.object().unknown(true).optional(),
  isDeleted: Joi.boolean().required(),
  deletedOn: Joi.string().pattern(ISO_DATE_PATTERN),
  createdAt: Joi.string().pattern(ISO_DATE_PATTERN),
  updatedAt: Joi.string().pattern(ISO_DATE_PATTERN),
  traits: Joi.array().allow(null),
  traitsCount: Joi.number().optional(),
  defaultAddress: Joi.array().allow(null),
  __v: Joi.number().optional(),
});

const responseV3ObjectSchema = customerJoi.object({
  id: Joi.string().required(),
  name: Joi.object(),
  status: Joi.string(),
  emailAddress: Joi.string(),
  phone: Joi.object().unknown(true),
  externalId: Joi.string(),
  additionalAttributes: Joi.object().unknown(true).optional(),
  isDeleted: Joi.boolean().required(),
  // deletedAt: Joi.string().pattern(ISO_DATE_PATTERN),
  createdAt: Joi.string().pattern(ISO_DATE_PATTERN),
  updatedAt: Joi.string().pattern(ISO_DATE_PATTERN),
});

export function validateCustomerListResponse(
  apiResponse: ListCustomersResponse,
  useV3 = false,
): boolean {
  validate.isArray(apiResponse?.data);

  const arraySchema = Joi.array().items(
    useV3 ? responseV3ObjectSchema : responseObjectSchema,
  );
  const { error } = arraySchema.validate(apiResponse?.data, {
    abortEarly: false,
  });

  if (error) {
    throw error;
  }

  return true;
}

export function validateCustomerSearchRequest(searchRequest: SearchCustomerRequest): boolean {
  const customerSearchRequestObject = Joi.object({
    limit: Joi.number().required(),
    offset: Joi.number().required(),
    sort: Joi.string(),
    match: Joi.object(),
  });

  const { error } = customerSearchRequestObject.validate(searchRequest, {
    abortEarly: false,
  });
  if (error) {
    throw error;
  }
  return true;
}

export function validateCustomerListRequest(apiRequest: ListCustomersRequest): boolean {
  const customerListRequestObject = Joi.object({
    limit: Joi.number().required(),
    offset: Joi.number().required(),
    sortOrder: Joi.string().valid('desc', 'asc'),
    sortBy: Joi.string(),
    name: Joi.string(),
    traitName: Joi.string(),
    isActive: Joi.bool(),
    endDateTo: Joi.string(),
  });
  const { error } = customerListRequestObject.validate(apiRequest, {
    abortEarly: false,
  });
  if (error) {
    throw error;
  }
  return true;
}

export function validateCustomerV3Response(
  apiResponse: CustomerV3,
): boolean {
  const { error } = responseV3ObjectSchema.validate(apiResponse);
  if (error) {
    throw error;
  }
  return true;
}

export function validateCustomerResponse(apiResponse: Customer): boolean {
  const { error } = responseObjectSchema.validate(apiResponse);
  if (error) {
    throw error;
  }
  return true;
}

export function validateCreateUserResponse(apiResponse: User): boolean {
  const createUserSchema = Joi.object({
    _id: Joi.string().required(),
    username: Joi.string(),
    userId: Joi.string(),
    title: Joi.string(),
    firstName: Joi.string(),
    lastName: Joi.string(),
    email: Joi.string(),
    primaryPhone: Joi.string(),
    status: Joi.string(),
    role: Joi.string(),
    isDeleted: Joi.boolean(),
    createdAt: Joi.string(),
    updatedAt: Joi.string(),
    additionalAttributes: Joi.any().optional(),
  });
  const { error } = createUserSchema.validate(apiResponse);
  if (error) {
    throw error;
  }
  return true;
}
export function validateCreateUserPartyResponse(apiResponse: UserPartyRequest): boolean {
  const createUserPartySchema = Joi.object({
    _id: Joi.string(),
    partyId: Joi.string().required(),
    userId: Joi.string().required(),
    createdAt: Joi.string(),
    updatedAt: Joi.string(),
  });
  const { error } = createUserPartySchema.validate(apiResponse);
  if (error) {
    throw error;
  }
  return true;
}
