import Joi from 'joi';
import validate from 'validate.js';
import { ISO_DATE_PATTERN } from 'lib/index';

import {
  OrganizationGroupCreateRequest,
  OrganizationGroupUpdateRequest,
  OrganizationGroupTypeList,
  OrganizationGroup,
  ListOrganizationGroupRequest,
  OrganizationGroupSortByList,
  ListOrganizationGroupResponse,
  ViewOrganizationGroupRequest,
  ListOrganizationGroupUsersAndAddressRequest,
} from '../../../../../@types';

const groupCreateRequest = Joi.object({
  groupType: Joi.string().valid(...OrganizationGroupTypeList).required(),
  name: Joi.string().required(),
  email: Joi.string(),
  primaryPhone: Joi.string(),
  partyId: Joi.string(),
  additionalAttributes: Joi.object({}).unknown(true),
});

const groupUpdateRequest = Joi.object({
  groupType: Joi.string().valid(...OrganizationGroupTypeList).required(),
  name: Joi.string(),
  email: Joi.string(),
  primaryPhone: Joi.string(),
  partyId: Joi.string(),
  groupId: Joi.string().required(),
  additionalAttributes: Joi.object({}).unknown(true),
});

const organizationGroup = groupCreateRequest.keys({
  _id: Joi.string().required(),
  createdAt: Joi.string().pattern(ISO_DATE_PATTERN).required(),
  updatedAt: Joi.string().pattern(ISO_DATE_PATTERN).required(),
  __v: Joi.number(),
  isDeleted: Joi.boolean(),
}).unknown(true);

const organizationGroupUserAddressListRequestObject = Joi.object({
  groupId: Joi.string().required(),
  organizationId: Joi.string().required(),
  limit: Joi.number().required(),
  offset: Joi.number().required(),
  type: Joi.string(),
  search: Joi.string().allow(null, ''),
  sortBy: Joi.string(),
  sortOrder: Joi.string().valid('desc', 'asc'),
});

export function validateOrganizationGroupCreateRequest(
  apiRequest: OrganizationGroupCreateRequest,
) : boolean {
  const { error } = groupCreateRequest.validate(apiRequest, { abortEarly: false });
  if (error) {
    throw error;
  }
  return true;
}

export function validateOrganizationGroupUpdateRequest(
  apiRequest: OrganizationGroupUpdateRequest,
) : boolean {
  const { error } = groupUpdateRequest.validate(apiRequest, { abortEarly: false });
  if (error) {
    throw error;
  }
  return true;
}

export function validateOrganizationGroup(
  groupObj: OrganizationGroup,
) : boolean {
  const { error } = organizationGroup.validate(groupObj, { abortEarly: false });
  if (error) {
    throw error;
  }
  return true;
}

export function validateOrganizationGroupListRequest(apiRequest: ListOrganizationGroupRequest):
boolean {
  const organizationGroupListRequestObject = Joi.object({
    limit: Joi.number().required(),
    offset: Joi.number().required(),
    search: Joi.string().allow(null, ''),
    sortBy: Joi.string().valid(...OrganizationGroupSortByList),
    sortOrder: Joi.string().valid('desc', 'asc'),
  });
  const { error } = organizationGroupListRequestObject.validate(apiRequest, { abortEarly: false });
  if (error) {
    throw error;
  }
  return true;
}

export function validateOrganizationGroupListResponse(apiResponse: ListOrganizationGroupResponse):
boolean {
  validate.isArray(apiResponse?.data);
  const arraySchema = Joi.array().items(organizationGroup);
  const { error } = arraySchema.validate(apiResponse?.data, { abortEarly: false });
  if (error) {
    throw error;
  }
  return true;
}

export function validateOrganizationGroupViewRequest(apiRequest: ViewOrganizationGroupRequest):
boolean {
  const organizationGroupViewRequestObject = Joi.object({
    organizationId: Joi.string().required(),
    groupId: Joi.string().required(),
  });
  const { error } = organizationGroupViewRequestObject.validate(apiRequest, { abortEarly: false });
  if (error) {
    throw error;
  }
  return true;
}

export function validateOrganizationGroupUsersAndAddressListRequest(
  apiRequest: ListOrganizationGroupUsersAndAddressRequest,
): boolean {
  const { error } = organizationGroupUserAddressListRequestObject.validate(
    apiRequest, { abortEarly: false },
  );
  if (error) {
    throw error;
  }
  return true;
}
