import validate from 'validate.js';
import Joi from 'joi';
import { CustomerAddress, CustomerAddressV3, ListCustomerAddressesResponse } from '@types';
import { ISO_DATE_PATTERN } from 'lib/index';

const responseObjectSchema = Joi.object({
  _id: Joi.string().required(),
  globalAddressId: Joi.number(),
  type: Joi.string(),
  addressLine1: Joi.string(), // required
  addressLine2: Joi.string().allow(''),
  addressLine3: Joi.string().allow(''),
  addressLine4: Joi.string().allow(''),
  county: Joi.string().allow(''),
  city: Joi.string(), // required
  state: Joi.string().allow(''),
  zipCode: Joi.alternatives().try(Joi.number(), Joi.string()).allow('', null),
  postalCode: Joi.string().allow('', null),
  country: Joi.string(), // required
  latitude: Joi.number(),
  longitude: Joi.number(),
  groupId: Joi.string(),
  additionalAttributes: Joi.object({}).unknown(true),
  isDeleted: Joi.boolean().required(),
  isDefault: Joi.boolean().required(),
  createdAt: Joi.string().pattern(ISO_DATE_PATTERN),
  updatedAt: Joi.string().pattern(ISO_DATE_PATTERN),
  __v: Joi.number().optional(),
});

const responseV3ObjectSchema = Joi.object({
  id: Joi.string().required(),
  globalAddressId: Joi.number(),
  address: Joi.object({
    type: Joi.string(),
    addressLine1: Joi.string(), // required
    addressLine2: Joi.string().allow(''),
    addressLine3: Joi.string().allow(''),
    addressLine4: Joi.string().allow(''),
    county: Joi.string().allow(''),
    city: Joi.string(), // required
    state: Joi.string().allow(''),
    region: Joi.string().allow(''),
    zipCode: Joi.alternatives().try(Joi.number(), Joi.string()).allow('', null),
    postalCode: Joi.string().allow('', null),
    country: Joi.string(), // required
  }),
  latitude: Joi.number(),
  longitude: Joi.number(),
  groupId: Joi.string(),
  additionalAttributes: Joi.object({}).unknown(true),
  isDeleted: Joi.boolean().required(),
  isDefault: Joi.boolean().required(),
  deletedAt: Joi.string().allow(null),
  createdAt: Joi.string().pattern(ISO_DATE_PATTERN),
  updatedAt: Joi.string().pattern(ISO_DATE_PATTERN),
});

export function validateListCustomerAddressesResponse(
  apiResponse: ListCustomerAddressesResponse,
  useV3 = false,
): boolean {
  validate.isArray(apiResponse?.data);

  const arraySchema = Joi.array().items(
    useV3 ? responseV3ObjectSchema : responseObjectSchema,
  );
  const { error } = arraySchema.validate(apiResponse?.data, {
    abortEarly: false,
  });

  if (error) {
    throw error;
  }

  return true;
}

export function validateCustomerAddressResponse(
  apiResponse: CustomerAddress | CustomerAddressV3,
  useV3 = false,
): boolean {
  const { error } = (useV3 ?
    responseV3ObjectSchema :
    responseObjectSchema
  ).validate(apiResponse);
  if (error) {
    throw error;
  }
  return true;
}
