import axios from 'axios';

const aamlAPI = axios.create();

aamlAPI.interceptors.request.use((config) => {
  const stageName = new URL(window.location.origin).hostname?.split('.')[0];
  const isIdv2User = localStorage.getItem('isLoggedInWithIdV2') === 'true';
  const { stage } = process.env;
  config.responseType = 'json';
  config.headers.Accept = 'application/json';
  config.headers['x-api-key'] = 'cFPNLib3K03q1RQrjHK5L5synb8gfaPe7q9VQdNV';
  if (!config.headers['x-site-context']) {
    config.headers['x-site-context'] = JSON.stringify({
      date: new Date().toISOString(),
      stage,
      channel: 12,
      account: isIdv2User ?
        sessionStorage.getItem('accountId') :
        sessionStorage.getItem('account'),
    });
  }
  config.baseURL = `https://${stageName}.na.aaml.fabric.inc`;
  return config;
});

export default aamlAPI;
