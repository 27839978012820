import { SKUPrice } from '../../../../@types';
import mockedSkuWarningItems from './mockedSkuWarning';

const mockedPrices: SKUPrice[] = [
  ...mockedSkuWarningItems,
  {
    sku: '10011',
    priceListId: 100445,
    productTitle: 'T-shirt 11',
    price: 12.4,
    createdAt: new Date(2021, 8, 11),
    startDate: '09/07/2021',
    endDate: '09/09/2021',
    status: 'Not Approved',
  },
  {
    sku: '10010',
    priceListId: 100445,
    productTitle: 'Shoes 10',
    price: 14.0,
    createdAt: new Date(2021, 7, 12),
    startDate: '02/07/2021',
    endDate: '04/07/2021',
    status: 'Approved',
  },
  {
    sku: '10012',
    priceListId: 100445,
    productTitle: 'Pants 12',
    price: 9.0,
    createdAt: new Date(2021, 6, 13),
    startDate: '04/03/2021',
    endDate: '03/04/2021',
    status: 'Not Approved',
  },
  {
    sku: '10014',
    priceListId: 100445,
    productTitle: 'Shoes 14',
    price: 17.0,
    createdAt: new Date(2021, 5, 14),
    startDate: '02/05/2021',
    endDate: '05/05/2021',
    status: 'Approved',
  },
  {
    sku: '10013',
    priceListId: 100445,
    productTitle: 'Shirt 13',
    price: 12.0,
    createdAt: new Date(2021, 4, 15),
    startDate: '01/09/2021',
    endDate: '09/01/2021',
    status: 'Not Approved',
  },
  {
    sku: '10015',
    priceListId: 100445,
    productTitle: 'Shirt 15',
    price: 14.0,
    createdAt: new Date(2021, 3, 16),
    startDate: '08/07/2021',
    endDate: '07/08/2021',
    status: 'Approved',
  },
  {
    sku: '10016',
    priceListId: 100445,
    productTitle: 'Wallet 16',
    price: 9.0,
    createdAt: new Date(2021, 2, 17),
    startDate: '06/07/2021',
    endDate: '07/06/2021',
    status: 'Not Approved',
  },
  {
    sku: '10017',
    priceListId: 100445,
    productTitle: 'Wallet 17',
    price: 8.0,
    createdAt: new Date(2021, 1, 18),
    startDate: '04/09/2021',
    endDate: '09/04/2021',
    status: 'Approved',
  },
  {
    sku: '10018',
    priceListId: 100445,
    productTitle: 'Socks 18',
    price: 2.0,
    createdAt: new Date(2021, 12, 19),
    startDate: '06/09/2021',
    endDate: '09/06/2021',
    status: 'Approved',
  },
  {
    sku: '10019',
    priceListId: 100445,
    productTitle: 'Socks 19',
    price: 3.0,
    createdAt: new Date(2021, 11, 20),
    startDate: '07/02/2021',
    endDate: '02/07/2021',
    status: 'Approved',
  },
  {
    sku: '10020',
    priceListId: 100445,
    productTitle: 'Belt 20',
    price: 4.0,
    createdAt: new Date(2021, 10, 21),
    startDate: '03/01/2021',
    endDate: '01/03/2021',
    status: 'Not Approved',
  },
  {
    sku: '10021',
    priceListId: 100445,
    productTitle: 'Shirt 21',
    price: 15.0,
    createdAt: new Date(2021, 9, 22),
    startDate: '08/04/2021',
    endDate: '04/08/2021',
    status: 'Approved',
  },
  {
    sku: '10022',
    priceListId: 100445,
    productTitle: 'Socks 22',
    price: 4.0,
    createdAt: new Date(2021, 8, 23),
    startDate: '02/08/2021',
    endDate: '08/02/2021',
    status: 'Not Approved',
  },
  {
    sku: '10023',
    priceListId: 100445,
    productTitle: 'Shirt 23',
    price: 12.0,
    createdAt: new Date(2021, 4, 15),
    startDate: '01/09/2021',
    endDate: '09/01/2021',
    status: 'Not Approved',
  },
  {
    sku: '10024',
    priceListId: 100445,
    productTitle: 'Shirt 24',
    price: 14.0,
    createdAt: new Date(2021, 3, 16),
    startDate: '08/07/2021',
    endDate: '07/08/2021',
    status: 'Approved',
  },
  {
    sku: '10025',
    priceListId: 100445,
    productTitle: 'Wallet 25',
    price: 9.0,
    createdAt: new Date(2021, 2, 17),
    startDate: '06/07/2021',
    endDate: '07/06/2021',
    status: 'Not Approved',
  },
  {
    sku: '10026',
    priceListId: 100445,
    productTitle: 'Wallet 26',
    price: 8.0,
    createdAt: new Date(2021, 1, 18),
    startDate: '04/09/2021',
    endDate: '09/04/2021',
    status: 'Approved',
  },
  {
    sku: '10027',
    priceListId: 100445,
    productTitle: 'Socks 27',
    price: 2.0,
    createdAt: new Date(2021, 12, 19),
    startDate: '06/09/2021',
    endDate: '09/06/2021',
    status: 'Approved',
  },
  {
    sku: '10028',
    priceListId: 100445,
    productTitle: 'Socks 28',
    price: 3.0,
    createdAt: new Date(2021, 11, 20),
    startDate: '07/02/2021',
    endDate: '02/07/2021',
    status: 'Approved',
  },
  {
    sku: '10029',
    priceListId: 100445,
    productTitle: 'Belt 29',
    price: 4.0,
    createdAt: new Date(2021, 10, 21),
    startDate: '03/01/2021',
    endDate: '01/03/2021',
    status: 'Not Approved',
  },
  {
    sku: '10030',
    priceListId: 100445,
    productTitle: 'Shirt 30',
    price: 15.0,
    createdAt: new Date(2021, 9, 22),
    startDate: '08/04/2021',
    endDate: '04/08/2021',
    status: 'Approved',
  },
  {
    sku: '10031',
    priceListId: 100445,
    productTitle: 'Socks 31',
    price: 4.0,
    createdAt: new Date(2021, 8, 23),
    startDate: '02/08/2021',
    endDate: '08/02/2021',
    status: 'Not Approved',
  },
];

export default mockedPrices;
