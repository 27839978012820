import validate from 'validate.js';
import Joi from 'joi';
import {
  CreatePartyTraitParams, DeletePartyTraitResponse,
  ListTraitsRequest,
  ListTraitsResponse, Trait,
} from '@types';
import { ISO_DATE_PATTERN } from 'lib/index';

const responseObjectSchema = Joi.object({
  _id: Joi.string().required(),
  isDeleted: Joi.boolean(),
  traitId: Joi.string(),
  name: Joi.string(),
  createdAt: Joi.string().pattern(ISO_DATE_PATTERN),
  updatedAt: Joi.string().pattern(ISO_DATE_PATTERN),
  __v: Joi.number(),
});

const partyTraitObjectSchema = Joi.object({
  _id: Joi.string().required(),
  partyId: Joi.string(),
  traitId: Joi.string(),
  traitScore: Joi.number(),
});

const deleteTraitObjectSchema = Joi.object({
  message: Joi.string(),
});

export function validateTraitListResponse(apiResponse: ListTraitsResponse): boolean {
  validate.isArray(apiResponse?.data);

  const arraySchema = Joi.array().items(responseObjectSchema);
  const { error } = arraySchema.validate(apiResponse?.data, { abortEarly: false });

  if (error) {
    throw error;
  }

  return true;
}

export function validateTraitListRequest(apiRequest: ListTraitsRequest) {
  const traitListRequestObject = Joi.object({
    limit: Joi.number().required(),
    offset: Joi.number().required(),
    sortOrder: Joi.string().valid('desc', 'asc'),
    sortBy: Joi.string(),
    filter: Joi.string().optional(),
  });
  const { error } = traitListRequestObject.validate(apiRequest, { abortEarly: false });
  if (error) {
    throw error;
  }
  return true;
}

export function validateCreateTraitResponse(apiResponse: Trait) {
  const { error } = responseObjectSchema.validate(apiResponse);
  if (error) {
    throw error;
  }
  return true;
}

export function validateCreatePartyTraitResponse(apiResponse: CreatePartyTraitParams) {
  const { error } = partyTraitObjectSchema.validate(apiResponse);
  if (error) {
    throw error;
  }
  return true;
}

export function validateDeletePartyTraitResponse(apiResponse: DeletePartyTraitResponse) {
  const { error } = deleteTraitObjectSchema.validate(apiResponse);
  if (error) {
    throw error;
  }
  return true;
}
