export default [
  {
    id: 1,
    name: '£ - Great Britsh Pound (GBP)',
  },
  {
    id: 2,
    name: '$ - Canadian Dollar (CAD)',
  },
  {
    id: 3,
    name: '₱ - Philippine Peso (PHP)',
  },
  {
    id: 4,
    name: '₹ - Indian Rupee (INR)',
  },
];
