/* eslint-disable comma-dangle */
import {
  ListContractsResponse,
  ListContractsRequest,
  ErrorResponse,
  Contract,
  ContractViewRequest,
  DeletedAddressResponse,
  ListOrganizationRequest,
  ListOrganizationResponse,
  ListOrganizationAddressRequest,
  ListOrganizationAddressResponse,
  OrganizationAddress,
  OrganizationAddressViewRequest,
  OrganizationUser,
  OrganizationUserViewRequest,
  CreateOrganizationUserRequest,
  ListOrganizationUserRequest,
  ListOrganizationUserResponse,
  EditOrganizationUserRequest,
  EditOrganizationUserResponse,
  DeleteOrganizationUserRequest,
  DeleteOrganizationUserResponse,
  CreateOrganizationUserResponse,
  CreateContractRequest,
  EditContractRequest,
  OrganizationGroupCreateRequest,
  OrganizationGroupUpdateRequest,
  OrganizationGroup,
  ListOrganizationGroupRequest,
  ListOrganizationGroupResponse,
  ViewOrganizationGroupRequest,
  ListOrganizationGroupUsersAndAddressRequest,
  OrganizationResponse,
  OrganizationRequest,
  ViewOrganizationRequest,
  ListCustomersRequest,
  ListCustomersResponse,
  ListTraitsRequest,
  ListTraitsResponse,
  Customer,
  ListCustomerAddressesRequest,
  ListCustomerAddressesResponse,
  CustomerAddress,
  User,
  UserPartyRequest,
  UserPartyResponse,
  Trait,
  CreatePartyTraitParams,
  DeletePartyTraitParams,
  DeletePartyTraitResponse,
  SearchCustomerRequest,
  CustomerV3,
  CustomerAddressV3,
} from '@types';
import {
  validateListContractResponse,
  validateContractListRequest,
  validateContractViewRequest,
  validateContractResponse,
  validateCreateContractRequest,
  validateUpdateContractRequest,
} from 'src/controllers/csr/contracts/validators';
import {
  updateContractService,
  listContractsService,
  ViewContractsService,
  createContractService,
} from 'src/controllers/csr/contracts/services';
import {
  listOrganizationService,
  createOrganizationService,
  updateOrganizationService,
  viewOrganizationService,
} from 'src/controllers/csr/organization/services';
import {
  validateOrganizationListResponse,
  validateOrganizationListRequest,
  validateOrganizationRequest,
  validateOrganizationResponse,
  validateGetOrganizationRequest,
} from 'src/controllers/csr/organization/validators';
import {
  listOrganizationAddressService,
  OrganizationAddressCreateService,
  OrganizationAddressViewService,
  OrganizationAddressEditService,
} from 'src/controllers/csr/organization-address/services';
import {
  validateOrganizationAddressListRequest,
  validateOrganizationAddressListResponse,
  validateOrganizationAddressCreateRequest,
  validateOrganizationAddressViewResponse,
  validateOrganizationAddressViewRequest,
  validateOrganizationAddressEditRequest,
} from 'src/controllers/csr/organization-address/validators';
import {
  createOrganizationUserService,
  viewOrganizationUserService,
  listOrganizationUserService,
  editOrganizationUserService,
  deleteOrganizationUserService,
} from 'src/controllers/csr/organization-user/services';
import {
  validateOrganizationUserViewResponse,
  validateOrganizationUserViewDeleteRequest,
  validateCreateOrganizationUserRequest,
  validateCreateEditOrganizationUserResponse,
  validateOrganizationUserListRequest,
  validateOrganizationUserListResponse,
  validateOrganizationUserDeleteResponse,
  validateEditOrganizationUserRequest,
} from 'src/controllers/csr/organization-user/validators';
import {
  createOrganizationGroupService,
  updateOrganizationGroupService,
  listOrganizationGroupService,
  viewOrganizationGroupService,
  listOrganizationGroupUsersService,
  listOrganizationGroupAddressService,
} from 'src/controllers/csr/organization-group/services';
import {
  validateOrganizationGroup,
  validateOrganizationGroupCreateRequest,
  validateOrganizationGroupUpdateRequest,
  validateOrganizationGroupListRequest,
  validateOrganizationGroupListResponse,
  validateOrganizationGroupViewRequest,
  validateOrganizationGroupUsersAndAddressListRequest,
} from 'src/controllers/csr/organization-group/validators';
import {
  validateCreateUserPartyResponse,
  validateCreateUserResponse,
  validateCustomerListRequest,
  validateCustomerListResponse,
  validateCustomerSearchRequest,
  validateCustomerResponse,
  validateCustomerV3Response,
} from 'src/controllers/csr/customers/validators';
import {
  createCustomerService,
  createCustomerServiceV3,
  createUserPartyService,
  createUserService,
  listCustomersService,
  updateCustomerService,
  updateCustomerServiceV3,
  updateCustomerStatusService,
  viewCustomerService,
  viewCustomerServiceV3,
} from 'src/controllers/csr/customers/services';
import {
  validateCreatePartyTraitResponse,
  validateCreateTraitResponse,
  validateDeletePartyTraitResponse,
  validateTraitListRequest,
  validateTraitListResponse,
} from 'src/controllers/csr/traits/validators';
import {
  createPartyTraitService,
  createTraitService,
  deletePartyTraitService,
  listTraitsService,
} from 'src/controllers/csr/traits/services';

import axios, { AxiosError } from 'axios';
import {
  createCustomerAddressService,
  createCustomerAddressServiceV3,
  deleteCustomerAddressServiceV3,
  listCustomerAddressesService,
  listCustomerAddressesServiceV3,
  updateCustomerAddressService,
  updateCustomerAddressServiceV3,
} from 'src/controllers/csr/customers-addresses/services';
import {
  validateCustomerAddressResponse,
  validateListCustomerAddressesResponse,
} from 'src/controllers/csr/customers-addresses/validators';

const reportError = (error: Error | AxiosError): ErrorResponse => {
  // send the error to our logging service...
  let message = '';
  let statusCode = 400;
  if (axios.isAxiosError(error)) {
    // Access to config, request, and response
    message = error.message;
    statusCode = error.response.status;
  } else if (error instanceof Error) {
    // Just a stock error
    message = String(error.message);
  }
  return { error: { message, statusCode } };
};

export async function ViewOrganizationAddress(
  params: OrganizationAddressViewRequest
): Promise<OrganizationAddress | ErrorResponse> {
  try {
    validateOrganizationAddressViewRequest(params);
    const response = await OrganizationAddressViewService(params);
    validateOrganizationAddressViewResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function CreateOrganizationAddress(
  params: OrganizationAddress,
  organizationId: string
): Promise<OrganizationAddress | ErrorResponse> {
  try {
    validateOrganizationAddressCreateRequest(params, organizationId);
    const response = await OrganizationAddressCreateService(
      params,
      organizationId
    );
    validateOrganizationAddressViewResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function EditOrganizationAddress(
  params: OrganizationAddress,
  organizationId: string,
  orgAddrId: string
): Promise<OrganizationAddress | ErrorResponse> {
  try {
    validateOrganizationAddressEditRequest(params, organizationId, orgAddrId);
    const response = await OrganizationAddressEditService(
      params,
      organizationId,
      orgAddrId
    );
    validateOrganizationAddressViewResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function listOrganizationAddress(
  params: ListOrganizationAddressRequest,
  organizationId: string
): Promise<ListOrganizationAddressResponse | ErrorResponse> {
  try {
    validateOrganizationAddressListRequest(params, organizationId);
    const response = await listOrganizationAddressService(
      params,
      organizationId
    );
    validateOrganizationAddressListResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function listContracts(
  params: ListContractsRequest
): Promise<ListContractsResponse | ErrorResponse> {
  try {
    validateContractListRequest(params);
    const response = await listContractsService(params);
    validateListContractResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function viewCustomerV3(
  customerId: string
): Promise<CustomerV3 | ErrorResponse> {
  try {
    const response = await viewCustomerServiceV3(customerId);
    validateCustomerV3Response(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function createCustomerV3(
  customer: Partial<CustomerV3>
): Promise<CustomerV3 | ErrorResponse> {
  try {
    const response = await createCustomerServiceV3(customer);
    validateCustomerV3Response(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function viewCustomer(
  customerId: string
): Promise<Customer | ErrorResponse> {
  try {
    const response = await viewCustomerService(customerId);
    validateCustomerResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function createCustomer(
  customer: Partial<Customer>
): Promise<Customer | ErrorResponse> {
  try {
    const response = await createCustomerService(customer);
    validateCustomerResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function updateCustomerV3(
  customer: Partial<CustomerV3>,
  customerId: string
): Promise<CustomerV3 | ErrorResponse> {
  try {
    const response = await updateCustomerServiceV3(customer, customerId);
    validateCustomerV3Response(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function updateCustomer(
  customer: Partial<Customer>,
  customerId: string
): Promise<Customer | ErrorResponse> {
  try {
    const response = await updateCustomerService(customer, customerId);
    validateCustomerResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function updateCustomerStatus(
  status: string,
  customerId: string
): Promise<{ type: string; message: string; status?: string } | ErrorResponse> {
  try {
    const response = await updateCustomerStatusService(status, customerId);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function createUser(
  user: Partial<User>
): Promise<User | ErrorResponse> {
  try {
    const response = await createUserService(user);
    validateCreateUserResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function createUserParty(
  userPartyRequest: UserPartyRequest
): Promise<UserPartyResponse | ErrorResponse> {
  try {
    const response = await createUserPartyService(userPartyRequest);
    validateCreateUserPartyResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function listCustomerAddresses(
  customerId: string,
  params: ListCustomerAddressesRequest,
  useV3 = false
): Promise<ListCustomerAddressesResponse | ErrorResponse> {
  try {
    const response = useV3
      ? await listCustomerAddressesServiceV3(customerId, params)
      : await listCustomerAddressesService(customerId, params);
    validateListCustomerAddressesResponse(response, useV3);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function createCustomerAddress(
  customerId: string,
  params: Partial<CustomerAddress>
): Promise<CustomerAddress | ErrorResponse> {
  try {
    const response = await createCustomerAddressService(customerId, params);
    validateCustomerAddressResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function createCustomerAddressV3(
  customerId: string,
  params: Partial<CustomerAddressV3>
): Promise<CustomerAddressV3 | ErrorResponse> {
  try {
    const response = await createCustomerAddressServiceV3(customerId, params);
    validateCustomerAddressResponse(response, true);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function updateCustomerAddress(
  customerId: string,
  params: Partial<CustomerAddress>
): Promise<CustomerAddress | ErrorResponse> {
  try {
    const response = await updateCustomerAddressService(customerId, params);
    validateCustomerAddressResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function updateCustomerAddressV3(
  customerId: string,
  params: Partial<CustomerAddressV3>
): Promise<CustomerAddressV3 | DeletedAddressResponse | ErrorResponse> {
  try {
    if (params.isDeleted) {
      return await deleteCustomerAddressServiceV3(customerId, params);
    }
    const response = await updateCustomerAddressServiceV3(customerId, params);
    validateCustomerAddressResponse(response, true);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function searchCustomer(
  params: SearchCustomerRequest
): Promise<ListCustomersResponse | ErrorResponse> {
  try {
    validateCustomerSearchRequest(params);
    const response = await listCustomersService(params, true);
    validateCustomerListResponse(response, true);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function listCustomers(
  params: ListCustomersRequest
): Promise<ListCustomersResponse | ErrorResponse> {
  try {
    validateCustomerListRequest(params);
    const response = await listCustomersService(params);
    validateCustomerListResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function listTraits(
  params: ListTraitsRequest
): Promise<ListTraitsResponse | ErrorResponse> {
  try {
    validateTraitListRequest(params);
    const response = await listTraitsService(params);
    validateTraitListResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function createTrait(
  params: Trait
): Promise<Trait | ErrorResponse> {
  try {
    const response = await createTraitService(params);
    validateCreateTraitResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function createPartyTrait(
  params: CreatePartyTraitParams
): Promise<CreatePartyTraitParams | ErrorResponse> {
  try {
    const response = await createPartyTraitService(params);
    validateCreatePartyTraitResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function deletePartyTrait(
  params: DeletePartyTraitParams
): Promise<DeletePartyTraitResponse | ErrorResponse> {
  try {
    const response = await deletePartyTraitService(params);
    validateDeletePartyTraitResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function ViewContract(
  params: ContractViewRequest
): Promise<Contract | ErrorResponse> {
  try {
    validateContractViewRequest(params);
    const response = await ViewContractsService(params);
    validateContractResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function listOrganization(
  params: ListOrganizationRequest
): Promise<ListOrganizationResponse | ErrorResponse> {
  try {
    validateOrganizationListRequest(params);
    const response = await listOrganizationService(params);
    validateOrganizationListResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function createOrganization(
  params: OrganizationRequest
): Promise<OrganizationResponse | ErrorResponse> {
  try {
    validateOrganizationRequest(params);
    const response = await createOrganizationService(params);
    validateOrganizationResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function updateOrganization(
  params: OrganizationRequest,
  organizationId: string
): Promise<OrganizationResponse | ErrorResponse> {
  try {
    validateOrganizationRequest(params);
    const response = await updateOrganizationService(params, organizationId);
    validateOrganizationResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function viewOrganization(
  params: ViewOrganizationRequest
): Promise<OrganizationResponse | ErrorResponse> {
  try {
    validateGetOrganizationRequest(params);
    const response = await viewOrganizationService(params.organizationId);
    validateOrganizationResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function viewOrganizationUser(
  params: OrganizationUserViewRequest
): Promise<OrganizationUser | ErrorResponse> {
  try {
    validateOrganizationUserViewDeleteRequest(params);
    const response = await viewOrganizationUserService(params);
    validateOrganizationUserViewResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function createOrganizationUser(
  params: CreateOrganizationUserRequest
): Promise<CreateOrganizationUserResponse | ErrorResponse> {
  try {
    validateCreateOrganizationUserRequest(params);
    const response = await createOrganizationUserService(params);
    validateCreateEditOrganizationUserResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function listOrganizationUser(
  params: ListOrganizationUserRequest
): Promise<ListOrganizationUserResponse | ErrorResponse> {
  try {
    validateOrganizationUserListRequest(params);
    const response = await listOrganizationUserService(params);
    validateOrganizationUserListResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function deleteOrganizationUser(
  params: DeleteOrganizationUserRequest
): Promise<DeleteOrganizationUserResponse | ErrorResponse> {
  try {
    validateOrganizationUserViewDeleteRequest(params);
    const response = await deleteOrganizationUserService(params);
    validateOrganizationUserDeleteResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function editOrganizationUser(
  params: EditOrganizationUserRequest
): Promise<EditOrganizationUserResponse | ErrorResponse> {
  try {
    validateEditOrganizationUserRequest(params);
    const response = await editOrganizationUserService(params);
    validateCreateEditOrganizationUserResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function createContract(
  params: CreateContractRequest
): Promise<Contract | ErrorResponse> {
  try {
    validateCreateContractRequest(params);
    const response = await createContractService(params);
    validateContractResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function updateContract(
  params: EditContractRequest
): Promise<Contract | ErrorResponse> {
  try {
    validateUpdateContractRequest(params);
    const response = await updateContractService(params);
    validateContractResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function createOrganizationGroup(
  params: OrganizationGroupCreateRequest,
  organizationId: string
): Promise<OrganizationGroup | ErrorResponse> {
  try {
    validateOrganizationGroupCreateRequest(params);
    const response = await createOrganizationGroupService(
      params,
      organizationId
    );
    validateOrganizationGroup(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function updateOrganizationGroup(
  params: OrganizationGroupUpdateRequest,
  organizationId: string
): Promise<OrganizationGroup | ErrorResponse> {
  try {
    validateOrganizationGroupUpdateRequest(params);
    const response = await updateOrganizationGroupService(
      params,
      organizationId
    );
    validateOrganizationGroup(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function listOrganizationGroup(
  params: ListOrganizationGroupRequest,
  organizationId: string
): Promise<ListOrganizationGroupResponse | ErrorResponse> {
  try {
    validateOrganizationGroupListRequest(params);
    const response = await listOrganizationGroupService(params, organizationId);
    validateOrganizationGroupListResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function viewOrganizationGroup(
  params: ViewOrganizationGroupRequest
): Promise<OrganizationGroup | ErrorResponse> {
  try {
    validateOrganizationGroupViewRequest(params);
    const response = await viewOrganizationGroupService(params);
    validateOrganizationGroup(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function listOrganizationGroupUsers(
  params: ListOrganizationGroupUsersAndAddressRequest
): Promise<ListOrganizationUserResponse | ErrorResponse> {
  try {
    validateOrganizationGroupUsersAndAddressListRequest(params);
    const response = await listOrganizationGroupUsersService(params);
    validateOrganizationUserListResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}

export async function listOrganizationGroupAddress(
  params: ListOrganizationGroupUsersAndAddressRequest
): Promise<ListOrganizationAddressResponse | ErrorResponse> {
  try {
    validateOrganizationGroupUsersAndAddressListRequest(params);
    const response = await listOrganizationGroupAddressService(params);
    validateOrganizationAddressListResponse(response);
    return response;
  } catch (error) {
    return reportError(error);
  }
}
