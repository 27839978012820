import {
  ListOrganizationResponse,
  ListOrganizationRequest,
  OrganizationRequest,
  OrganizationResponse,
} from '@types';
import customerAPI from 'src/services/customerApi';
import { CUSTOMER_API_ROUTE } from 'lib/index';

export async function listOrganizationService(
  params: ListOrganizationRequest,
): Promise<ListOrganizationResponse> {
  const { data } = await customerAPI.get<ListOrganizationResponse>(
    `${CUSTOMER_API_ROUTE}/v1/organizations`,
    {
      params,
    },
  );
  return data;
}

export async function createOrganizationService(
  params: OrganizationRequest,
): Promise<OrganizationResponse> {
  const { data } = await customerAPI.post<OrganizationResponse>(
    `${CUSTOMER_API_ROUTE}/v1/organizations/`,
    {
      ...params,
    },
  );
  return data;
}

export async function updateOrganizationService(
  params: OrganizationRequest,
  organizationId: string,
): Promise<OrganizationResponse> {
  const { data } = await customerAPI.put<OrganizationResponse>(
    `${CUSTOMER_API_ROUTE}/v1/organizations/${organizationId}`,
    {
      ...params,
    },
  );
  return data;
}

export async function viewOrganizationService(
  organizationId: string,
): Promise<OrganizationResponse> {
  const { data } = await customerAPI.get<OrganizationResponse>(
    `${CUSTOMER_API_ROUTE}/v1/organizations/${organizationId}`,
  );
  return data;
}
