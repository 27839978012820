const determineAIVersion = (aiSearchQuery: string): string => {
  switch (aiSearchQuery) {
    case 'v1':
    case 'v2':
      return aiSearchQuery;
    default:
      return 'v1';
  }
};

export default determineAIVersion;
