import axios from 'axios';
import { SESSION_STORAGE_KEYS } from 'src/lib/constants';


const salesDashboardApi = axios.create({
  baseURL: `${process.env.SALES_AUTH_URL}`,
});

salesDashboardApi.interceptors.request.use((request) => {
  request.headers['x-fabric-tenant-id'] = sessionStorage.getItem(
    SESSION_STORAGE_KEYS.ACCOUNT_ID,
  );
  request.headers.Authorization = sessionStorage.getItem(SESSION_STORAGE_KEYS.ACCESS_TOKEN);
  return request;
});

export default salesDashboardApi;
