import {
  ListCustomersResponse,
  ListCustomersRequest,
  Customer,
  User,
  UserPartyResponse,
  UserPartyRequest,
  CustomerV3,
} from '@types';
import api from 'services/api';
import customerAPI from 'src/services/customerApi';
import { CUSTOMER_API_ROUTE, CUSTOMER_V3_API_PATH, objectToQueryParams } from 'lib/index';

export async function listCustomersService (
  params: ListCustomersRequest,
  useV3 = false,
): Promise<ListCustomersResponse> {
  if (useV3) {
    const { data } = await api.post<ListCustomersResponse>(
      `${CUSTOMER_V3_API_PATH}/search`,
      params,
    );
    return data;
  }

  const { data } = await customerAPI.get<ListCustomersResponse>(
    `${CUSTOMER_API_ROUTE}/v1/individuals?${objectToQueryParams(params)}`,
  );
  return data;
}

export async function viewCustomerServiceV3(
  customerId: string,
): Promise<CustomerV3> {
  const { data } = await api.get<CustomerV3>(
    `${CUSTOMER_V3_API_PATH}/${customerId}`,
  );
  return data;
}

export async function createCustomerServiceV3 (
  customer: Partial<CustomerV3>,
): Promise<CustomerV3> {
  const { data } = await api.post<CustomerV3>(CUSTOMER_V3_API_PATH, customer);
  return data;
}

export async function viewCustomerService (
  customerId: string,
): Promise<Customer> {
  const { data } = await customerAPI.get<Customer>(`${CUSTOMER_API_ROUTE}/v1/individuals/${customerId}`);
  return data;
}
export async function createCustomerService (
  customer: Partial<Customer>,
): Promise<Customer> {
  const { data } = await customerAPI.post<Customer>(`${CUSTOMER_API_ROUTE}/v1/individuals`, customer);
  return data;
}

export async function updateCustomerServiceV3 (
  customer: Partial<CustomerV3>,
  customerId: string,
): Promise<CustomerV3> {
  const { data } = await api.patch<CustomerV3>(`${CUSTOMER_V3_API_PATH}/${customerId}`, customer);
  return data;
}

export async function updateCustomerService (
  customer: Partial<Customer>,
  customerId: string,
): Promise<Customer> {
  const { data } = await customerAPI.put<Customer>(`${CUSTOMER_API_ROUTE}/v1/individuals/${customerId}`, customer);
  return data;
}

export async function updateCustomerStatusService (
  status: string,
  customerId: string,
): Promise<{type: string; message: string; status?: string}> {
  const { data } = await api.post<{type: string; message: string; status?: string}>(`${CUSTOMER_V3_API_PATH}/${customerId}/actions/update-status`, {
    status,
  });
  return data;
}

export async function createUserService (
  user: Partial<User>,
): Promise<User> {
  const { data } = await customerAPI.post<User>(`${CUSTOMER_API_ROUTE}/v1/users`, user);
  return data;
}

export async function createUserPartyService (
  userPartyRequest: UserPartyRequest,
): Promise<UserPartyResponse> {
  const { data } = await customerAPI.post<UserPartyResponse>(`${CUSTOMER_API_ROUTE}/v1/user-party`, userPartyRequest);
  return data;
}
