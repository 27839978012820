import {
  ListContractsResponse,
  ListContractsRequest,
  CreateContractRequest,
  Contract,
  ContractViewRequest,
  EditContractRequest,
} from '@types';
import customerAPI from 'src/services/customerApi';
import { CUSTOMER_API_ROUTE, objectToQueryParams } from 'lib/index';

export async function listContractsService (
  params: ListContractsRequest,
): Promise<ListContractsResponse> {
  const { data } = await customerAPI.get<ListContractsResponse>(`${CUSTOMER_API_ROUTE}/v1/contracts?${objectToQueryParams(params)}`);
  return data;
}

export async function ViewContractsService (
  params: ContractViewRequest,
): Promise<Contract> {
  const { data } = await customerAPI.get<Contract>(`${CUSTOMER_API_ROUTE}/v1/contracts/${params.contractId}`);
  return data;
}

export async function createContractService(
  params: CreateContractRequest,
): Promise<Contract> {
  const { data } = await customerAPI.post<Contract>(`${CUSTOMER_API_ROUTE}/v1/contracts`, params);
  return data;
}

export async function updateContractService(
  params: EditContractRequest,
): Promise<Contract> {
  const { data } = await customerAPI.patch<Contract>(`${CUSTOMER_API_ROUTE}/v1/contracts/${params.contractId}`, params.data);
  return data;
}
