import { StatusCodes } from 'http-status-codes';
import {
  AnalyticsReportRequest,
  AnalyticsReportResponse,
  ErrorResponse,
  JWTResponse,
} from '../../../@types';

import { fetchAnalyticsReportService, fetchJWTService } from './services';
import { validateAnalyticsReport, validateJWTResponse } from './validators';

export async function fetchAnalyticsReport({
  customerId,
  domain,
  status,
  type,
  offset,
  limit,
}: AnalyticsReportRequest): Promise<AnalyticsReportResponse | ErrorResponse> {
  try {
    const response = await fetchAnalyticsReportService({
      customerId,
      domain,
      status,
      type,
      offset,
      limit,
    });
    validateAnalyticsReport(response.reports);
    return response;
  } catch (err) {
    return {
      error: {
        message: err.message,
        statusCode: err.status ?? StatusCodes.INTERNAL_SERVER_ERROR,
      },
    };
  }
}

export async function fetchJWT(
  accessToken : string,
): Promise<JWTResponse | ErrorResponse> {
  try {
    const response = await fetchJWTService({
      accessToken,
    });
    //validateJWTResponse(response);
    return response;
  } catch (err) {
    return {
      error: {
        message: err.message,
        statusCode: err.status ?? StatusCodes.INTERNAL_SERVER_ERROR,
      }
    }
  }
}
