import {
  OrganizationGroupCreateRequest,
  OrganizationGroupUpdateRequest,
  OrganizationGroup,
  ListOrganizationGroupRequest,
  ListOrganizationGroupResponse,
  ViewOrganizationGroupRequest,
  ListOrganizationGroupUsersAndAddressRequest,
  ListOrganizationUserResponse,
  ListOrganizationAddressResponse,
} from '@types';
import customerAPI from 'src/services/customerApi';
import { CUSTOMER_API_ROUTE } from 'lib/index';

export async function createOrganizationGroupService (
  params: OrganizationGroupCreateRequest,
  organizationId: string,
): Promise<OrganizationGroup> {
  const { data } = await customerAPI.post<OrganizationGroup>(`${CUSTOMER_API_ROUTE}/v1/organizations/${organizationId}/groups`, {
    ...params,
  });
  return data;
}

export async function updateOrganizationGroupService (
  params: OrganizationGroupUpdateRequest,
  organizationId: string,
): Promise<OrganizationGroup> {
  const { groupId, ...rest } = params;
  const { data } = await customerAPI.put<OrganizationGroup>(`${CUSTOMER_API_ROUTE}/v1/organizations/${organizationId}/groups/${groupId}`, {
    ...rest,
  });
  return data;
}

export async function listOrganizationGroupService (
  params: ListOrganizationGroupRequest,
  organizationId: string,
) : Promise<ListOrganizationGroupResponse> {
  const { data } = await customerAPI.get<ListOrganizationGroupResponse>(`${CUSTOMER_API_ROUTE}/v1/organizations/${organizationId}/groups`, {
    params,
  });
  return data;
}

export async function viewOrganizationGroupService (
  params: ViewOrganizationGroupRequest,
) : Promise<OrganizationGroup> {
  const { organizationId, groupId } = params;
  const { data } = await customerAPI.get<OrganizationGroup>(`${CUSTOMER_API_ROUTE}/v1/organizations/${organizationId}/groups/${groupId}`, {
    params,
  });
  return data;
}

export async function listOrganizationGroupUsersService (
  params: ListOrganizationGroupUsersAndAddressRequest,
) : Promise<ListOrganizationUserResponse> {
  const { organizationId, groupId, ...rest } = params;
  const { data } = await customerAPI.get<ListOrganizationUserResponse>(`${CUSTOMER_API_ROUTE}/v1/organizations/${organizationId}/groups/${groupId}/users`, {
    params: rest,
  });
  return data;
}

export async function listOrganizationGroupAddressService (
  params: ListOrganizationGroupUsersAndAddressRequest,
) : Promise<ListOrganizationAddressResponse> {
  const { organizationId, groupId, ...rest } = params;
  const { data } = await customerAPI.get<ListOrganizationAddressResponse>(`${CUSTOMER_API_ROUTE}/v1/organizations/${organizationId}/groups/${groupId}/addresses`, {
    params: rest,
  });
  return data;
}
