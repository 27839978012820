const determineBackendUrl = (backendSearchQuery: string): string => {
  switch (backendSearchQuery) {
    case 'dev-ecs':
      return 'https://dev.commerce-gpt.fabric.inc/commerce-gpt';
    case 'dev-eks':
      return 'https://dev.api.fabric.inc/v1/commerce-gpt';
    case 'stg-eks':
      return 'https://stg.api.fabric.inc/v1/commerce-gpt';
    default:
      return process.env.COMMERCE_GPT_API;
  }
};

export default determineBackendUrl;
