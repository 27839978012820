import Joi from 'joi';
import { AnalyticsReportRequest, JWTRequest, JWTResponse } from '../../../../@types';

export function validateGetAnalyticsReportRequest(
  request: AnalyticsReportRequest
): boolean {
  const analyticsReportRequestObject = Joi.object({
    customerId: Joi.string().required(),
    domain: Joi.string().required(),
    status: Joi.string().required(),
    type: Joi.string().required(),
    offset: Joi.number().required(),
    limit: Joi.number().required(),
  });

  const { error } = analyticsReportRequestObject.validate(request, {
    abortEarly: false,
  });
  if (error) {
    throw error;
  }
  return true;
}

export function validateAnalyticsReport(reports: any[]): boolean {
  const analyticsReportResponseObject = Joi.array().items({
    id: Joi.string().required(),
    title: Joi.string().required(),
    widgetId: Joi.string().required(),
    desc: Joi.string().required(),
    dashboardId: Joi.string().required(),
    lastUpdated: Joi.string().required(),
    status: Joi.string().required(),
    type: Joi.string().required(),
    jaql: Joi.object().required(),
  });
  const { error } = analyticsReportResponseObject.validate(reports, {
    abortEarly: false,
  });
  if (error) {
    throw error;
  }
  return true;
}

export function validateJWTRequest(request: JWTRequest): boolean {
  const jwtResponseObject = Joi.object({
    accessToken: Joi.string().required(),
  });
  const { error } = jwtResponseObject.validate(request, {
    abortEarly: false,
  });
  if (error) {
    throw error;
  }
  return true;
}

export function validateJWTResponse(response: JWTResponse): boolean {
  const jwtResponseObject = Joi.object({
    sisense: Joi.object({
      jwt: Joi.string().required(),
    }).required(),
  });
  const { error } = jwtResponseObject.validate(response, {
    abortEarly: false,
  });
  if (error) {
    throw error;
  }
  return true;
}
