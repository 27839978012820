import customerAPI from 'src/services/customerApi';
import { CUSTOMER_API_ROUTE } from '../../../../lib';
import {
  OrganizationUserViewRequest,
  OrganizationUser,
  CreateOrganizationUserRequest,
  ListOrganizationUserRequest,
  ListOrganizationUserResponse,
  EditOrganizationUserRequest,
  EditOrganizationUserResponse,
  DeleteOrganizationUserRequest,
  DeleteOrganizationUserResponse,
  CreateOrganizationUserResponse,
} from '../../../../../@types';

export async function viewOrganizationUserService (
  params: OrganizationUserViewRequest,
): Promise<OrganizationUser> {
  const { organizationId, userId } = params;
  const { data } = await customerAPI.get<OrganizationUser>(`${CUSTOMER_API_ROUTE}/v1/organizations/${organizationId}/users/${userId}`, {
    params,
  });
  return data;
}

export async function createOrganizationUserService (
  params: CreateOrganizationUserRequest,
): Promise<CreateOrganizationUserResponse> {
  const { organizationId, ...rest } = params;
  const { data } = await customerAPI.post<CreateOrganizationUserResponse>(`${CUSTOMER_API_ROUTE}/v1/organizations/${organizationId}/users`, {
    ...rest,
  });
  return data;
}

export async function listOrganizationUserService (
  params: ListOrganizationUserRequest,
): Promise<ListOrganizationUserResponse> {
  const { organizationId } = params;
  delete params.organizationId;
  const { data } = await customerAPI.get<ListOrganizationUserResponse>(`${CUSTOMER_API_ROUTE}/v1/organizations/${organizationId}/users`, {
    params,
  });
  return data;
}

export async function editOrganizationUserService (
  params: EditOrganizationUserRequest,
): Promise<EditOrganizationUserResponse> {
  const { organizationId, userId, ...rest } = params;
  const { data } = await customerAPI.put<EditOrganizationUserResponse>(`${CUSTOMER_API_ROUTE}/v1/organizations/${organizationId}/users/${userId}`, {
    ...rest,
  });
  return data;
}

export async function deleteOrganizationUserService (
  params: DeleteOrganizationUserRequest,
): Promise<DeleteOrganizationUserResponse> {
  const { organizationId, userId } = params;
  const { data } = await customerAPI.delete<DeleteOrganizationUserResponse>(`${CUSTOMER_API_ROUTE}/v1/organizations/${organizationId}/users/${userId}`);
  return data;
}
