import {
  CustomerAddress,
  CustomerAddressV3,
  DeletedAddressResponse,
  ListCustomerAddressesRequest,
  ListCustomerAddressesResponse,
} from '@types';
import api from 'services/api';
import customerAPI from 'src/services/customerApi';
import { CUSTOMER_API_ROUTE, CUSTOMER_V3_API_PATH, objectToQueryParams } from 'lib/index';

export async function listCustomerAddressesServiceV3(
  customerId: string,
  params: ListCustomerAddressesRequest,
): Promise<ListCustomerAddressesResponse> {
  const { data } = await api.get<ListCustomerAddressesResponse>(
    `${CUSTOMER_V3_API_PATH}/${customerId}/customer-address?${objectToQueryParams(
      params,
    )}`,
  );
  return data;
}

export async function listCustomerAddressesService(
  customerId: string,
  params: ListCustomerAddressesRequest,
): Promise<ListCustomerAddressesResponse> {
  const { data } = await customerAPI.get<ListCustomerAddressesResponse>(
    `${CUSTOMER_API_ROUTE}/v1/individuals/${customerId}/addresses?${objectToQueryParams(params)}`,
  );
  return data;
}

export async function createCustomerAddressServiceV3(
  customerId: string,
  params: Partial<CustomerAddressV3>
): Promise<CustomerAddressV3> {
  const { data } = await api.post<CustomerAddressV3>(
    `${CUSTOMER_V3_API_PATH}/${customerId}/customer-address`,
    {
      ...params,
    }
  );
  return data;
}

export async function createCustomerAddressService(
  customerId: string,
  params: Partial<CustomerAddress>,
): Promise<CustomerAddress> {
  const { data } = await customerAPI.post<CustomerAddress>(
    `${CUSTOMER_API_ROUTE}/v1/individuals/${customerId}/addresses`,
    {
      ...params,
    },
  );
  return data;
}

export async function updateCustomerAddressService(
  customerId: string,
  params: Partial<CustomerAddress>,
): Promise<CustomerAddress> {
  const { data } = await customerAPI.put<CustomerAddress>(
    `${CUSTOMER_API_ROUTE}/v1/individuals/${customerId}/addresses/${params._id}`,
    {
      ...params,
    },
  );
  return data;
}

export async function updateCustomerAddressServiceV3(
  customerId: string,
  params: Partial<CustomerAddressV3>
): Promise<CustomerAddressV3> {
  const { data } = await api.put<CustomerAddressV3>(
    `${CUSTOMER_V3_API_PATH}/${customerId}/customer-address/${params.id}`,
    {
      ...params,
    }
  );
  return data;
}

export async function deleteCustomerAddressServiceV3(
  customerId: string,
  params: Partial<CustomerAddressV3>
): Promise<DeletedAddressResponse> {
  const { data } = await api.delete<DeletedAddressResponse>(
    `${CUSTOMER_V3_API_PATH}/${customerId}/customer-address/${params.id}`
  );
  return data;
}
