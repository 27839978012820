import {
  AnalyticsReportRequest,
  AnalyticsReportResponse,
  JWTRequest,
  JWTResponse,
} from '../../../../@types';
import { validateGetAnalyticsReportRequest, validateJWTRequest } from '../validators';
import api from '../../../services/api';
import aamlAPI from '../../../services/aamlAPI';

export async function fetchAnalyticsReportService(
  request: AnalyticsReportRequest,
): Promise<AnalyticsReportResponse> {
  validateGetAnalyticsReportRequest(request);
  const { data } = await api.get('/analytics/v1/reports', {
    params: {
      customerId: request.customerId,
      domain: request.domain,
      status: request.status,
      type: request.type,
      offset: request.offset,
      limit: request.limit,
    },
  });
  return data;
}

export async function fetchJWTService(
  request: JWTRequest,
): Promise<JWTResponse> {
  const {data} = await aamlAPI.get('/token-exchanger/exchange', {
    params: {
      jwt: request.accessToken,
    },
  });
  return data;
}