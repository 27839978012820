import axios from 'axios';
import determineBackendUrl from '../utils/determine-backend-url';

const commerceGPTAPI = axios.create({
  baseURL: process.env.COMMERCE_GPT_API,
});

commerceGPTAPI.interceptors.request.use((config) => {
  const searchParams = new URLSearchParams(window.location.search);
  const backend = searchParams.get('backend');
  if (backend) {
    config.baseURL = determineBackendUrl(backend);
  } else {
    config.baseURL = process.env.COMMERCE_GPT_API;
  }
  config.headers.Authorization = `Bearer ${sessionStorage.getItem(
    'accessToken',
  )}`;
  config.headers['x-fabric-tenant-id'] = sessionStorage.getItem(
    'accountId',
  );
  return config;
});

export default commerceGPTAPI;
