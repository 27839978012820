import validate from 'validate.js';
import Joi from 'joi';
import {
  ListOrganizationAddressRequest,
  ListOrganizationAddressResponse,
  OrganizationAddress,
  OrganizationAddressViewRequest,
} from '@types';
import { ISO_DATE_PATTERN } from 'lib/index';

const orgaddressObject = {
  _id: Joi.string().required(),
  type: Joi.string().valid('M', 'E', 'P'),
  globalAddressId: Joi.number(),
  addressLine1: Joi.string(),
  addressLine2: Joi.string(),
  addressLine3: Joi.string(),
  addressLine4: Joi.string(),
  county: Joi.string(),
  city: Joi.string(),
  state: Joi.string(),
  zipCode: Joi.string(),
  postalCode: Joi.string(),
  country: Joi.string(),
  latitude: Joi.string().allow('', null),
  longitude: Joi.string().allow('', null),
  groupId: Joi.string(),
  busOrResFlag: Joi.boolean(),
  hadLoadingDock: Joi.boolean(),
  additionalAttributes: Joi.object({}).unknown(true),
  isDeleted: Joi.boolean().required(),
  createdAt: Joi.string().pattern(ISO_DATE_PATTERN).required(),
  updatedAt: Joi.string().pattern(ISO_DATE_PATTERN).required(),
  __v: Joi.number(),
};

export function validateOrganizationAddressListResponse(apiResponse:
  ListOrganizationAddressResponse): boolean {
  validate.isArray(apiResponse?.data);
  const orgaddressObjectSchema = Joi.object(orgaddressObject).unknown(true);
  const arraySchema = Joi.array().items(orgaddressObjectSchema);
  const { error } = arraySchema.validate(apiResponse?.data, { abortEarly: false });
  if (error) {
    throw error;
  }
  return true;
}

export function validateOrganizationAddressListRequest(apiRequest: ListOrganizationAddressRequest,
  organizationId: string): boolean {
  const orgIdSchema = Joi.string().required();
  const validateOrgId = orgIdSchema.validate(organizationId);
  if (validateOrgId.error) {
    throw new Error('organizationId must be a string');
  }

  const requestObjectSchema = Joi.object({
    limit: Joi.number().required(),
    offset: Joi.number().required(),
    name: Joi.string().allow(null, ''),
    sortBy: Joi.string().required(),
    sortOrder: Joi.string().valid('desc', 'asc').required(),
    id: Joi.string(),
    type: Joi.string().valid('M', 'E', 'P'),
    addressLine1: Joi.string().allow(null, ''),
    city: Joi.string().allow(null, ''),
    country: Joi.string().allow(null, ''),
    postalCode: Joi.string().allow(null, ''),
  });

  const { error } = requestObjectSchema.validate(apiRequest, { abortEarly: false });
  if (error) {
    throw error;
  }

  return true;
}

export function validateOrganizationAddressCreateRequest(apiRequest:
  OrganizationAddress,
organizationId: string): boolean {
  const orgIdSchema = Joi.string().required();
  const validateOrgId = orgIdSchema.validate(organizationId);
  if (validateOrgId.error) {
    throw new Error('organization id must be a string');
  }
  const orgaddressCrateObject = { ...orgaddressObject };
  delete orgaddressCrateObject._id;
  delete orgaddressCrateObject.isDeleted;
  delete orgaddressCrateObject.createdAt;
  delete orgaddressCrateObject.updatedAt;

  const orgaddressObjectCreateSchema = Joi.object(orgaddressCrateObject);
  const { error } = orgaddressObjectCreateSchema.validate(apiRequest, { abortEarly: false });
  if (error) {
    throw error;
  }

  return true;
}

export function validateOrganizationAddressEditRequest(apiRequest:
  OrganizationAddress, organizationId: string, orgAddrId: string): boolean {
  const orgIdSchema = Joi.string().required();
  const validateOrgId = orgIdSchema.validate(organizationId);
  if (validateOrgId.error) {
    throw new Error('organization id must be a string');
  }

  const orgAddressIdSchema = Joi.string().required();
  const validateOrgAddressId = orgAddressIdSchema.validate(orgAddrId);
  if (validateOrgAddressId.error) {
    throw new Error('organization address id must be a string');
  }

  const orgaddressEditObject = { ...orgaddressObject };
  delete orgaddressEditObject._id;
  delete orgaddressEditObject.isDeleted;
  delete orgaddressEditObject.createdAt;
  delete orgaddressEditObject.updatedAt;

  const orgaddressObjectCreateSchema = Joi.object(orgaddressEditObject);
  const { error } = orgaddressObjectCreateSchema.validate(apiRequest, { abortEarly: false });
  if (error) {
    throw error;
  }

  return true;
}

/*
 * @returns boolean
 * This validation can be used for add, edit response and view as well
*/
export function validateOrganizationAddressViewResponse(
  apiResponse: OrganizationAddress,
): boolean {
  const orgaddressObjectSchema = Joi.object(orgaddressObject).unknown(true);
  const { error } = orgaddressObjectSchema.validate(apiResponse, { abortEarly: false });
  if (error) {
    throw error;
  }

  return true;
}

export function validateOrganizationAddressViewRequest(reqParams: OrganizationAddressViewRequest):
boolean {
  const requestObjectSchema = Joi.object({
    orgAddrId: Joi.string().required(),
    organizationId: Joi.string().required(),
  }).unknown(true);

  const { error } = requestObjectSchema.validate(reqParams, { abortEarly: false });
  if (error) {
    throw error;
  }
  if (error) {
    throw error;
  }

  return true;
}
