import axios from 'axios';
import validate from 'validate.js';
import { FeatureFlagRequest, FeatureFlagResponse } from '../../../@types';
import {
  FEATURE_FLAG_URL,
  FEATURE_FLAG_HEADER_TOKEN,
  FEATURE_FLAG_CUSTOMER_TOKEN,
  FEATURE_FLAG_ADMIN_TOKEN,
  FEATURE_FLAG_SUBSCRIPTION_TOKEN,
  FEATURE_FLAG_PAY_TOKEN,
} from './config';

async function getFeatureFlagService(
  request: FeatureFlagRequest,
  maxRetries = 3,
): Promise<any> {
  let retriesRemaining = maxRetries;
  let response = null;
  let FEATURE_FLAG_TOKEN = null;

  switch (request.projectId) {
    case 31570270:
      FEATURE_FLAG_TOKEN = FEATURE_FLAG_CUSTOMER_TOKEN;
      break;
    case 27810898:
      FEATURE_FLAG_TOKEN = FEATURE_FLAG_HEADER_TOKEN;
      break;
    case 26112280:
      FEATURE_FLAG_TOKEN = FEATURE_FLAG_ADMIN_TOKEN;
      break;
    case 29658574:
      FEATURE_FLAG_TOKEN = FEATURE_FLAG_SUBSCRIPTION_TOKEN;
      break;
    case 29612773:
      FEATURE_FLAG_TOKEN = FEATURE_FLAG_PAY_TOKEN;
      break;
    default:
      FEATURE_FLAG_TOKEN = FEATURE_FLAG_HEADER_TOKEN;
  }

  try {
    response = await axios
      .get(
        `${FEATURE_FLAG_URL}/${request.projectId}/feature_flags/${request.featureFlagName}`,
        {
          headers: { 'PRIVATE-TOKEN': FEATURE_FLAG_TOKEN },
        },
      )
      .then((res) => {
        const { data } = res;
        return data;
      });
  } catch (error) {
    if (retriesRemaining) {
      retriesRemaining -= 1;
      await getFeatureFlagService(request, retriesRemaining);
    }
    throw error;
  }
  return response;
}

function validateFeatureFlagResponse(apiResponse: any): undefined | Error {
  const baseError = validate(apiResponse, {
    name: { type: 'string' },
    description: { length: { minimum: 1 }, type: 'string' },
    active: { type: 'boolean' },
    version: { type: 'string' },
    created_at: {
      type: 'string',
      format: /\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d\.\d\d\dZ/,
    },
    updated_at: {
      type: 'string',
      format: /\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d\.\d\d\dZ/,
    },
    scopes: { presence: true },
    strategies: { presence: true, type: 'array' },
  });

  if (baseError) {
    throw baseError;
  }
  // eslint-disable-next-line
  for (const strategy of apiResponse.strategies) {
    const nestedError = validate(strategy, {
      id: { type: 'integer' },
      name: { type: 'string', length: { minimum: 1 } },
      parameters: { presence: true },
      scopes: { type: 'array' },
    });
    if (nestedError) {
      throw nestedError;
    }
  }

  return undefined;
}

async function getFeatureFlag(
  request: FeatureFlagRequest,
): Promise<FeatureFlagResponse> {
  try {
    const apiData = await getFeatureFlagService(request);
    validateFeatureFlagResponse(apiData);

    return {
      name: request.featureFlagName,
      active: apiData.active,
      strategies: apiData.strategies,
      version: apiData.version,
    };
  } catch (error) {
    // TODO propagate error to Datadog
    return {
      name: request.featureFlagName,
      active: false,
      error: true,
    };
  }
}

export default { getFeatureFlag, getFeatureFlagService };
