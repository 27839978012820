import axios from 'axios';
import { generateConfig, handleResponseError } from '../utils/apiConfig';

const customerAPI = axios.create({
  baseURL: process.env.V2_CUSTOMER_URL,
});

customerAPI.interceptors.request.use(generateConfig);
customerAPI.interceptors.response.use(
  response => response,
  handleResponseError,
);

export default customerAPI;
