import validate from 'validate.js';
import Joi, { ValidationError } from 'joi';
import {
  ListOrganizationRequest,
  ListOrganizationResponse,
  OrganizationRequest,
  OrganizationResponse,
  ViewOrganizationRequest,
} from '@types';
import { ISO_DATE_PATTERN } from 'lib/index';

const responseObjectSchema = Joi.object({
  _id: Joi.string().required(),
  partyType: Joi.string(),
  parentPartyId: Joi.alternatives(
    Joi.string(),
    Joi.number(),
  ).allow(null),
  isActive: Joi.boolean(),
  name: Joi.string(),
  email: Joi.string().allow(null),
  internalPartyId: Joi.string(),
  additionalAttributes: Joi.object({}).unknown(true),
  isDeleted: Joi.boolean().required(),
  createdAt: Joi.string().pattern(ISO_DATE_PATTERN).required(),
  updatedAt: Joi.string().pattern(ISO_DATE_PATTERN).required(),
  traits: Joi.array().allow(null),
  defaultAddress: Joi.array().allow(null),
  __v: Joi.number(),
}).unknown(true);

const createOrganizationSchema = Joi.object({
  name: Joi.string().required(),
  partyType: Joi.string().required(),
  email: Joi.string().required(),
  isActive: Joi.boolean(),
  internalPartyId: Joi.string(),
  additionalAttributes: Joi.object({}).unknown(true),
}).unknown(true);

export function validateOrganizationListResponse(
  apiResponse: ListOrganizationResponse,
): boolean {
  validate.isArray(apiResponse?.data);

  const arraySchema = Joi.array().items(responseObjectSchema);
  const { error } = arraySchema.validate(apiResponse?.data, {
    abortEarly: false,
  });

  if (error) {
    throw error;
  }

  return true;
}

export function validateOrganizationListRequest(
  apiRequest: ListOrganizationRequest,
) {
  const organizationListRequestObject = Joi.object({
    limit: Joi.number().required(),
    offset: Joi.number().required(),
    name: Joi.string().allow(null, ''),
    sortBy: Joi.string(),
    sortOrder: Joi.string().valid('desc', 'asc'),
    id: Joi.string(),
    isActive: Joi.boolean(),
    partyType: Joi.string().valid('O', 'H'),
  });
  const { error } = organizationListRequestObject.validate(apiRequest, {
    abortEarly: false,
  });
  if (error) {
    throw error;
  }
  return true;
}

export const validateOrganizationResponse = (
  apiResponse: OrganizationResponse,
): boolean | ValidationError => {
  const { error } = responseObjectSchema.validate(apiResponse, {
    abortEarly: false,
  });
  if (error) {
    throw error;
  }
  return true;
};

export const validateGetOrganizationRequest = (
  apiRequest: ViewOrganizationRequest,
): boolean | ValidationError => {
  const requestSchema = Joi.object({
    organizationId: Joi.string().required(),
  });

  const { error } = requestSchema.validate(apiRequest, { abortEarly: false });
  if (error) {
    throw error;
  }
  return true;
};

export function validateOrganizationRequest(
  apiRequest: OrganizationRequest,
): boolean | ValidationError {
  const { error } = createOrganizationSchema.validate(apiRequest, {
    abortEarly: false,
  });
  if (error) {
    throw error;
  }
  return true;
}
