import axios from 'axios';
import { generateConfig, handleResponseError } from '../utils/apiConfig';

const api = axios.create();

api.interceptors.request.use(generateConfig);
api.interceptors.response.use(
  response => response,
  handleResponseError,
);

export default api;
