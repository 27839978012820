import Joi from 'joi';
import {
  InviteUsersRequest,
  InviteUsersRequestV2,
  UserListResponse,
} from '../../../../@types';

export function validateInviteUsersRequest(apiResponse: InviteUsersRequest) {
  const userDetailsObjects = Joi.object({
    email: Joi.string().required(),
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    roles: Joi.array().required(),
  }).unknown(true);

  const inviteUsersObject = Joi.object({
    senderId: Joi.string().min(24).max(24).required(),
    accountId: Joi.string().required(),
    userDetails: Joi.array().items(userDetailsObjects).required(),
    provider: Joi.object({
      name: Joi.string().required(),
      registrationSite: Joi.string().required(),
    }).required(),
  }).unknown(true);
  const { error } = inviteUsersObject.validate(apiResponse, {
    abortEarly: false,
  });
  if (error) {
    throw error;
  }
  return true;
}

export function validateInviteUsersRequestV2(apiRequest: InviteUsersRequestV2) {
  const userDetails = Joi.object({
    email: Joi.string().required(),
    firstName: Joi.string().required(),
    middleName: Joi.string().optional(),
    lastName: Joi.string().required(),
    roles: Joi.array().required(),
  }).unknown(true);
  const inviteUsersArray = Joi.object({
    invites: Joi.array().items(userDetails).required(),
  });
  const { error } = inviteUsersArray.validate(apiRequest, {
    abortEarly: false,
  });
  if (error) {
    throw error;
  }
  return true;
}

export function validateEnableDisableUser(response) {
  const enableDisableSchema = Joi.object({
    status: Joi.string().required(),
    _id: Joi.string().required(),
  }).unknown(true);

  const { error } = enableDisableSchema.validate(response);
  if (error) {
    throw error;
  }
  return true;
}

export function validateRemoveUserV2(response) {
  const removeUserV2Schema = Joi.object({
    code: Joi.string().required(),
    message: Joi.string().required(),
    type: Joi.string(),
  }).unknown(true);

  const { error } = removeUserV2Schema.validate(response);
  if (error) {
    throw error;
  }
  return true;
}

export function validateResendInvite(response) {
  const resendInvite = Joi.boolean();
  const { error } = resendInvite.validate(response);
  if (error) {
    throw error;
  }
  return true;
}

export function validateResendInviteV2(response) {
  const resendInviteV2 = Joi.object({
    code: Joi.string().required(),
    message: Joi.string().required(),
    type: Joi.string(),
  }).unknown(true);

  const { error } = resendInviteV2.validate(response);
  if (error) {
    throw error;
  }
  return true;
}

export function validateRoleList(roleList) {
  const validateRoleListSchema = Joi.array().items({
    _id: Joi.string().required(),
    id: Joi.string().optional(),
    permissions: Joi.array().required(),
    account: Joi.string().required(),
    name: Joi.string().required(),
    description: Joi.string().required(),
    createdAt: Joi.string().required(),
    updatedAt: Joi.string().required(),
    __v: Joi.number().required(),
  });

  const { error } = validateRoleListSchema.validate(roleList);
  if (error) {
    throw error;
  }
  return true;
}

export function validateUserListV2(apiResponse: UserListResponse): boolean {

  const userListSchema = Joi.array().items(
    Joi.object().pattern(Joi.string(), Joi.object({
      roles: Joi.array().items(Joi.object({
        id: Joi.string(),
        name: Joi.string(),
      })),
      name: Joi.string(),
      email: Joi.string().allow(null),
      status: Joi.string().allow(null),
      createdAt: Joi.string(),
    }).unknown(true))
  );

  const { error } = userListSchema.validate(apiResponse?.users, { abortEarly: false });

  if (error) {
    throw error;
  }
  return true;
}

export function validateRoleListV2(roleList) {
  const validateRoleListV2Schema = Joi.array().items(
    Joi.object({
      id: Joi.string().required(),
      status: Joi.string().required(),
      accountId: Joi.string().required(),
      name: Joi.string().required(),
      description: Joi.string().required(),
      policies: Joi.array().items(
        Joi.object({
          id: Joi.string().required(),
          status: Joi.string().required(),
          accountId: Joi.string().required(),
          name: Joi.string().required(),
          description: Joi.string().required(),
          permissions: Joi.array().items(
            Joi.object({
              id: Joi.string().required(),
              status: Joi.string().required(),
              accountId: Joi.string().allow(null).required().allow(null),
              name: Joi.string().required(),
              description: Joi.string().required(),
              code: Joi.string().required(),
              product: Joi.string().required(),
              version: Joi.string().required(),
              resource: Joi.string().required(),
              action: Joi.string().required(),
              createdAt: Joi.string().required(),
              updatedAt: Joi.string().allow(null),
              deletedAt: Joi.string().allow(null),
            })
          ),
          conditions: Joi.array(),
          createdAt: Joi.string().required(),
          updatedAt: Joi.string().allow(null),
          deletedAt: Joi.string().allow(null),
        })
      ),
      createdAt: Joi.string().required(),
      updatedAt: Joi.string().allow(null),
      deletedAt: Joi.string().allow(null),
    }).unknown(true)
  );

  const { error } = validateRoleListV2Schema.validate(roleList);
  if (error) {
    throw error;
  }
  return true;
}

export function validateAddRoleToUserV2(response): boolean {
  const validatorSchema = Joi.object({
    status: Joi.string().required(),
    accountId: Joi.string().required(),
    userId: Joi.string().required(),
    roleId: Joi.string().required(),
    tenantId: Joi.string().required(),
    userinfo: Joi.object().optional(),
    roles: Joi.array().empty(),
  }).unknown(true);
  const { error } = validatorSchema.validate(response);
  if (error) {
    throw error;
  }
  return true;
}

export function validateRemoveRoleFromUserV2(response): boolean {
  const removeSchema = Joi.object({
    accountId: Joi.string().required(),
    roleId: Joi.string().required(),
    status: Joi.string().required(),
    tenantId: Joi.string().required(),
    userId: Joi.string().required(),
    userinfo: Joi.object().optional(),
    roles: Joi.array().empty(),
  }).unknown(true);
  const { error } = removeSchema.validate(response);
  if (error) {
    throw error;
  }
  return true;
}

export function validateUpdateUserV2(response): boolean {
  const updatedUserSchema = Joi.object({
    id: Joi.string().required(),
    status: Joi.string().required(),
    orgId: Joi.string().allow(null),
    loginId: Joi.string().required(),
    type: Joi.string().required(),
    email: Joi.string().required(),
    primaryContact: Joi.string().allow(null),
    firstName: Joi.string().empty(),
    lastName: Joi.string().empty(),
    meta: Joi.string().allow(null),
    createdAt: Joi.string().allow(null),
    updatedAt: Joi.string().allow(null),
    deletedAt: Joi.string().allow(null),
    isStaffUserFederated: Joi.boolean().required(),
    organizationId: Joi.string().allow(null),
  }).unknown(true);
  const { error } = updatedUserSchema.validate(response);
  if (error) {
    throw error;
  }
  return true;
}

export function validateUpdatePasswordV2(response): boolean {
  const validatorSchema = Joi.object({
    code: Joi.string().required(),
    message: Joi.string().required(),
    type: Joi.string(),
  }).unknown(true);
  const { error } = validatorSchema.validate(response);
  if (error) {
    throw error;
  }
  return true;
}
