export const generateConfig = (config) => {
  config.headers.Authorization = sessionStorage.getItem('accessToken');
  const { stage } = process.env;
  const account =
    localStorage.getItem('isLoggedInWithIdV2') === 'true'
      ? sessionStorage.getItem('accountId')
      : sessionStorage.getItem('account');
  
  if (!config.headers['x-site-context']) {
    config.headers['x-site-context'] =
      `{ "stage":"${stage}", "date": "${new Date().toISOString()}", "channel": 12, "account": "${account}" }`;
  }
  
  if (!config.headers['x-fabric-tenant-id']) {
    config.headers['x-fabric-tenant-id'] = account;
  }
  
  return config;
};

export const handleResponseError = async (error: any) => {
  if (error && error.response && error.response.status === 403) {
    window.location.assign(`${window.location.origin}/auth/v2/login`);
    return null;
  }
  return Promise.reject(error);
};
