import Joi from 'joi';
import validate from 'validate.js';
import { ISO_DATE_PATTERN } from '../../../../lib';
import {
  OrganizationUserViewRequest,
  OrganizationUser,
  OrganizationUserStatusList,
  CreateOrganizationUserRequest,
  ListOrganizationUserRequest,
  ListOrganizationUserResponse,
  DeleteOrganizationUserResponse,
  EditOrganizationUserRequest,
  CreateOrganizationUserResponse,
} from '../../../../../@types';

const userCommonKeys = Joi.object({
  username: Joi.string().required(),
  title: Joi.string().allow(null, ''),
  userId: Joi.string(),
  globalCustomerId: Joi.string(),
  userType: Joi.string(),
  firstName: Joi.string(),
  lastName: Joi.string(),
  email: Joi.string(),
  primaryPhone: Joi.string().allow(null, ''),
  additionalAttributes: Joi.object({}).unknown(true),
  status: Joi.string().valid(...OrganizationUserStatusList),
  role: Joi.string(),
  groupId: Joi.string().allow(null, ''),
  __v: Joi.number(),
}).unknown(true);

const userListResponseObjectSchema = userCommonKeys.keys({
  _id: Joi.string().required(),
  secondaryPhone: Joi.string(),
  userUuid: Joi.string(),
  isAdmin: Joi.boolean(),
  isOwner: Joi.boolean(),
  canPlaceOrder: Joi.boolean(),
  canSeePrice: Joi.boolean(),
  canViewCatalog: Joi.boolean(),
  canViewOrderHistory: Joi.boolean(),
  isDeleted: Joi.boolean().required(),
  createdAt: Joi.string().pattern(ISO_DATE_PATTERN).required(),
  updatedAt: Joi.string().pattern(ISO_DATE_PATTERN).required(),
});

const organizationUserCreateRequestObject = Joi.object({
  username: Joi.string().required(),
  organizationId: Joi.string().required(),
  title: Joi.string().allow(null, ''),
  firstName: Joi.string(),
  lastName: Joi.string(),
  email: Joi.string().required(),
  primaryPhone: Joi.string().allow(null, ''),
  status: Joi.string().valid(...OrganizationUserStatusList),
  role: Joi.string(),
  additionalAttributes: Joi.object({}).unknown(true),
  groupId: Joi.string().allow(null, ''),
}).unknown(true);

const userCreateEditResponseObjectSchema = userCommonKeys.keys({
  _id: Joi.string().required(),
  isDeleted: Joi.boolean().required(),
  createdAt: Joi.string().pattern(ISO_DATE_PATTERN).required(),
  updatedAt: Joi.string().pattern(ISO_DATE_PATTERN).required(),
  additionalAttributes: Joi.object({}).unknown(true),
});

export const organizationUserListRequestObject = Joi.object({
  limit: Joi.number().required(),
  offset: Joi.number().required(),
  search: Joi.string().allow(null, ''),
  organizationId: Joi.string().required(),
  sortBy: Joi.string(),
  sortOrder: Joi.string().valid('desc', 'asc'),
});

export function validateOrganizationUserViewResponse(apiResponse: OrganizationUser): boolean {
  const { error } = userListResponseObjectSchema.validate(apiResponse, { abortEarly: false });
  if (error) {
    throw error;
  }
  return true;
}

export function validateOrganizationUserViewDeleteRequest (
  apiRequest: OrganizationUserViewRequest,
): boolean {
  const organizationUserViewRequestObject = Joi.object({
    userId: Joi.string().required(),
    organizationId: Joi.string().required(),
  });
  const { error } = organizationUserViewRequestObject.validate(apiRequest, { abortEarly: false });
  if (error) {
    throw error;
  }
  return true;
}

export function validateCreateEditOrganizationUserResponse(
  apiResponse: CreateOrganizationUserResponse,
): boolean {
  const { error } = userCreateEditResponseObjectSchema.validate(apiResponse, { abortEarly: false });
  if (error) {
    throw error;
  }
  return true;
}

export function validateCreateOrganizationUserRequest (
  apiRequest: CreateOrganizationUserRequest,
): boolean {
  const { error } = organizationUserCreateRequestObject.validate(apiRequest, { abortEarly: false });
  if (error) {
    throw error;
  }
  return true;
}

export function validateOrganizationUserListResponse(apiResponse: ListOrganizationUserResponse):
boolean {
  validate.isArray(apiResponse?.list);
  const arraySchema = Joi.array().items(userListResponseObjectSchema);
  const { error } = arraySchema.validate(apiResponse?.list, { abortEarly: false });
  if (error) {
    throw error;
  }
  return true;
}

export function validateOrganizationUserListRequest(apiRequest: ListOrganizationUserRequest):
boolean {
  const { error } = organizationUserListRequestObject.validate(apiRequest, { abortEarly: false });
  if (error) {
    throw error;
  }
  return true;
}

export function validateOrganizationUserDeleteResponse (
  apiResponse: DeleteOrganizationUserResponse,
): boolean {
  const organizationUserDeleteResponseObject = Joi.object({
    message: Joi.string().required(),
  });
  const { error } = organizationUserDeleteResponseObject
    .validate(apiResponse, { abortEarly: false });
  if (error) {
    throw error;
  }
  return true;
}

export function validateEditOrganizationUserRequest (
  apiRequest: EditOrganizationUserRequest,
): boolean {
  const organizationUserEditRequestSchema =
  organizationUserCreateRequestObject.keys({ userId: Joi.string().required() });
  const { error } = organizationUserEditRequestSchema.validate(apiRequest, { abortEarly: false });
  if (error) {
    throw error;
  }
  return true;
}
