import validate from 'validate.js';
import Joi from 'joi';
import {
  ListContractsResponse,
  ListContractsRequest,
  CreateContractRequest,
  Contract,
  ContractViewRequest,
  EditContractRequest,
} from '@types';
import { ISO_DATE_PATTERN } from 'lib/index';

const responseObjectSchema = Joi.object({
  _id: Joi.string().required(),
  contractId: Joi.string().required(),
  partyId: Joi.string(),
  onContract: Joi.bool(),
  promotionStacking: Joi.bool(),
  addressId: Joi.string(),
  accountId: Joi.string(),
  contractType: Joi.string(),
  status: Joi.string(),
  startDate: Joi.string().pattern(ISO_DATE_PATTERN).required(),
  endDate: Joi.string().pattern(ISO_DATE_PATTERN).required(),
  additionalAttributes: Joi.object({}).unknown(true),
  party: Joi.object({
    _id: Joi.string().required(),
    isDeleted: Joi.boolean().required(),
    parentPartyId: Joi.string().allow(null),
    name: Joi.string(),
    email: Joi.string(),
    partyType: Joi.string().required(),
    isActive: Joi.boolean(),
    createdAt: Joi.string().pattern(ISO_DATE_PATTERN).required(),
    updatedAt: Joi.string().pattern(ISO_DATE_PATTERN).required(),
    additionalAttributes: Joi.object({}).unknown(true),
    __v: Joi.number(),
  }),
  isDeleted: Joi.boolean().required(),
  createdAt: Joi.string().pattern(ISO_DATE_PATTERN).required(),
  updatedAt: Joi.string().pattern(ISO_DATE_PATTERN).required(),
  priceLists: Joi.array().allow(null),
  contractPriceList: Joi.object({}).unknown(true),
  __v: Joi.number(),
});

export function validateListContractResponse(apiResponse: ListContractsResponse): boolean {
  validate.isArray(apiResponse?.data);

  const arraySchema = Joi.array().items(responseObjectSchema);
  const { error } = arraySchema.validate(apiResponse?.data, { abortEarly: false });

  if (error) {
    throw error;
  }

  return true;
}

export function validateContractListRequest(apiRequest: ListContractsRequest) {
  const contractListRequestObject = Joi.object({
    limit: Joi.number().required(),
    offset: Joi.number().required(),
    search: Joi.string().allow(null, ''),
    sortBy: Joi.string(),
    sortOrder: Joi.string().valid('desc', 'asc'),
    startDateFrom: Joi.string().pattern(ISO_DATE_PATTERN),
    startDateTo: Joi.string().pattern(ISO_DATE_PATTERN),
    endDateFrom: Joi.string().pattern(ISO_DATE_PATTERN),
    endDateTo: Joi.string().pattern(ISO_DATE_PATTERN),
    status: Joi.array().items(Joi.string().valid('ACTIVE', 'INACTIVE', 'DRAFT')),
  });
  const { error } = contractListRequestObject.validate(apiRequest, { abortEarly: false });
  if (error) {
    throw error;
  }
  return true;
}

export function validateContractResponse(apiResponse: Contract): boolean {
  const { error } = responseObjectSchema.validate(apiResponse, { abortEarly: false });

  if (error) {
    throw error;
  }

  return true;
}

export function validateContractViewRequest(apiRequest: ContractViewRequest) {
  const contractViewRequestObject = Joi.object({
    contractId: Joi.string().required(),
  });
  const { error } = contractViewRequestObject.validate(apiRequest, { abortEarly: false });

  if (error) {
    throw error;
  }
  return true;
}

export function validateCreateContractRequest(apiRequest: CreateContractRequest): boolean | Error {
  const contractCreateRequestObject = Joi.object({
    partyId: Joi.string().required(),
    status: Joi.string().required().valid('ACTIVE', 'INACTIVE', 'DRAFT'),
    priceListId: Joi.number(),
    startDate: Joi.string().pattern(ISO_DATE_PATTERN).required(),
    endDate: Joi.string().pattern(ISO_DATE_PATTERN).required(),
    onContract: Joi.bool(),
    promotionStacking: Joi.bool(),
    additionalAttributes: Joi.object({}).unknown(true),
  });
  const { error } = contractCreateRequestObject.validate(apiRequest, { abortEarly: false });
  if (error) {
    throw error;
  }
  return true;
}

export function validateUpdateContractRequest(apiRequest: EditContractRequest): boolean | Error {
  const contractUpdateRequestObject = Joi.object({
    data: Joi.object({
      partyId: Joi.string(),
      priceListId: Joi.number(),
      status: Joi.string().valid('ACTIVE', 'INACTIVE', 'DRAFT'),
      startDate: Joi.string().pattern(ISO_DATE_PATTERN),
      endDate: Joi.string().pattern(ISO_DATE_PATTERN),
      onContract: Joi.bool(),
      promotionStacking: Joi.bool(),
      additionalAttributes: Joi.object({}).unknown(true),
    }),
    contractId: Joi.string().required(),
  });
  const { error } = contractUpdateRequestObject.validate(apiRequest, { abortEarly: false });
  if (error) {
    throw error;
  }
  return true;
}
