import {
  CreatePartyTraitParams, DeletePartyTraitParams, DeletePartyTraitResponse,
  ListTraitsRequest,
  ListTraitsResponse, Trait,
} from '@types';
import customerAPI from 'src/services/customerApi';
import { CUSTOMER_API_ROUTE, objectToQueryParams } from 'lib/index';

export async function listTraitsService (
  params: ListTraitsRequest,
): Promise<ListTraitsResponse> {
  const { data } = await customerAPI.get<ListTraitsResponse>(`${CUSTOMER_API_ROUTE}/v1/trait?${objectToQueryParams(params)}`);
  return data;
}

export async function createTraitService (
  params: Trait,
): Promise<Trait> {
  const { data } = await customerAPI.post<Trait>(`${CUSTOMER_API_ROUTE}/v1/trait`, params);
  return data;
}

export async function createPartyTraitService(
  params: CreatePartyTraitParams,
): Promise<CreatePartyTraitParams> {
  const { data } = await customerAPI.post<CreatePartyTraitParams>(`${CUSTOMER_API_ROUTE}/v1/party-trait`, params);
  return data;
}

export async function deletePartyTraitService(
  params: DeletePartyTraitParams,
): Promise<DeletePartyTraitResponse> {
  const { data } = await customerAPI.delete<DeletePartyTraitResponse>(`${CUSTOMER_API_ROUTE}/v1/party-trait`, {
    data: { ...params },
  });
  return data;
}
