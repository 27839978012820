import axios from 'axios';
import determineAIVersion from 'src/utils/determine-ai-version';
import determineBackendUrl from '../utils/determine-backend-url';

const commerceGPTAPI = axios.create({
  baseURL: process.env.COMMERCE_GPT_API,
});

commerceGPTAPI.interceptors.request.use((config) => {
  const searchParams = new URLSearchParams(window.location.search);
  const backend = searchParams.get('backend');
  config.baseURL = determineBackendUrl(backend);
  const version = searchParams.get('version');
  config.headers['x-fabric-ai-version'] = determineAIVersion(version);

  config.headers.Authorization = `Bearer ${sessionStorage.getItem(
    'accessToken',
  )}`;
  config.headers['x-fabric-tenant-id'] = sessionStorage.getItem(
    'accountId',
  );
  return config;
});

export default commerceGPTAPI;
