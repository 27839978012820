import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  AddRoleToUserRequest,
  AddRoleToUserResponse,
  EnableDisableRequest,
  EnabledUser,
  IdentityV2Response,
  InviteUsersRequest,
  InviteUsersRequestV2,
  InviteUsersResponseV2,
  InviteUsersResponse,
  RemoveRoleToUserRequest,
  RemoveRoleToUserResponse,
  ResendInviteRequest,
  RoleListResponse,
  RoleListV2Response,
  UserListRequest,
  UserListResponse,
  UpdateUserResponse,
  UpdateUserRequest,
  UpdatePasswordV2Request,
  UpdatePasswordV2Response,
  UpdateUserRolesRequest,
  TenantOrganizationResponse,
  LabelItem,
  UpdateUserRoleLabelResponse,
  UpdateUserRolesRequestWithLabels,
} from '../../../../@types';
import api from '../../../services/api';
import apiDx from '../../../services/apiDx';
import identityV2API from '../../../services/identityV2API';
import {
  validateInviteUsersRequest,
  validateInviteUsersRequestV2,
} from '../validators';

export async function inviteUsersService(
  request: InviteUsersRequest
): Promise<InviteUsersResponse> {
  validateInviteUsersRequest(request);
  const { data } = await api.post<InviteUsersResponse>(
    `/api-identity/user/${request.senderId}/invite-users`,
    {
      accountId: request.accountId,
      provider: request.provider,
      senderId: request.senderId,
      userDetails: request.userDetails,
    }
  );
  return data;
}

export async function inviteUsersServiceV2(
  request: InviteUsersRequestV2,
  enableValidation
): Promise<InviteUsersResponseV2> {
  if (enableValidation) {
    validateInviteUsersRequestV2(request);
  }
  const { data } = await identityV2API.post<InviteUsersResponseV2>(
    '/ums/v2/users',
    request
  );
  return data;
}

export async function enableDisableUserService(request: EnableDisableRequest) {
  const { data } = await api.patch<EnabledUser>(
    '/api-identity/user/enable-disable',
    request
  );
  return data;
}

export async function removeUserServiceV2(userId: string) {
  const { data } = await identityV2API.delete<IdentityV2Response>(
    `/ums/v2/users/${userId}`
  );
  return data;
}

export async function resendInviteService(request: ResendInviteRequest) {
  const { data } = await api.post<boolean>(
    `/api-identity/user/${request.userId}/resend-invite-user`,
    {
      redeemerId: request.userId,
      senderId: request.senderId,
      userId: request.userId,
    }
  );
  return data;
}

export async function resendInviteV2Service(userId: string) {
  const { data } = await identityV2API.post<IdentityV2Response>(
    `ums/v2/users/${userId}/resend-invite`
  );
  return data;
}

export async function fetchRoleListService(): Promise<RoleListResponse> {
  const { data } = await api.get('/api-identity/role');
  return data;
}

export async function fetchUserListServiceV2(
  request: UserListRequest
): Promise<UserListResponse> {
  const response: AxiosResponse = await identityV2API.get('/ums/v2/users', {
    params: {
      offset: request.offset,
      limit: request.limit,
    },
  });
  return {
    users: response.data.users,
    query: {
      count: response.data.query.count,
    },
  };
}

export async function fetchUserListServiceV3(
  request: UserListRequest
): Promise<UserListResponse> {
  const response: AxiosResponse = await identityV2API.post(
    '/ums/v2/users/search',
    request?.data || {},
    {
      params: {
        offset: request.offset,
        limit: request.limit,
      },
    }
  );
  return {
    users: response.data.users,
    query: {
      count: response.data.query.count,
    },
  };
}

export async function fetchUsers(
  requestConfig: AxiosRequestConfig
): Promise<unknown> {
  const response = await identityV2API.get('/ums/v2/users', requestConfig);
  return response;
}

export async function updatePasswordServiceV2(
  request: UpdatePasswordV2Request
): Promise<UpdatePasswordV2Response> {
  const response: AxiosResponse = await identityV2API.post(
    '/ums/v2/auth/self/password',
    request
  );
  return response.data;
}

export async function fetchRolesServiceV2(): Promise<RoleListV2Response> {
  const response: AxiosResponse = await identityV2API.get('/rbac/v2/roles');
  return response.data;
}

/**
 * @deprecated Use updateUserRolesServiceV2 instead
 */
export async function addRoleToUserServiceV2({
  userId,
  roleId,
}: AddRoleToUserRequest): Promise<AddRoleToUserResponse> {
  const response: AxiosResponse = await identityV2API.post(
    `/rbac/v2/users/${userId}/roles/${roleId}`
  );
  return response.data;
}

/**
 * @deprecated Use updateUserRolesServiceV2 instead
 */
export async function removeRoleFromUserServiceV2({
  userId,
  roleId,
}: RemoveRoleToUserRequest): Promise<RemoveRoleToUserResponse> {
  const response: AxiosResponse = await identityV2API.delete(
    `/rbac/v2/users/${userId}/roles/${roleId}`
  );
  return response.data;
}

export async function updateUserRolesServiceV2({
  userId,
  roles,
}: UpdateUserRolesRequest): Promise<AxiosResponse> {
  return identityV2API.put(`/rbac/v2/users/${userId}/roles`, { roles });
}

export async function updateUserRolesServiceV2WithLabels({
  userId,
  data,
}: UpdateUserRolesRequestWithLabels): Promise<AxiosResponse> {
  return identityV2API.put(`/rbac/v2/users/${userId}/roles`, data);
}

export async function updateUserServiceV2(
  user: UpdateUserRequest
): Promise<UpdateUserResponse> {
  const response: AxiosResponse = await identityV2API.put(
    '/ums/v2/users/self',
    user
  );
  return response.data;
}

// eslint-disable-next-line max-len
export async function fetchOrgDetailByTenantId(
  tenantId: string
): Promise<TenantOrganizationResponse> {
  const { data }: AxiosResponse = await apiDx.post(
    `tenants/${tenantId}/actions/get-organization`
  );
  return data;
}

export async function fetchLabelsService(request): Promise<LabelItem[]> {
  const { data }: AxiosResponse = await identityV2API.get('/v2/labels', {
    params: {
      offset: request.offset,
      limit: request.limit,
      status: request.status,
    },
  });

  return data;
}

export async function updateUserLabelService(
  request
): Promise<UpdateUserRoleLabelResponse> {
  const { data }: AxiosResponse = await identityV2API.post(
    `/rbac/v2/users/${request.userId}/roles/${request.roleId}`,
    request.data
  );
  return data;
}

export async function updateUserRolesService(
  request
): Promise<UpdateUserRoleLabelResponse> {
  const { data }: AxiosResponse = await identityV2API.put(
    `/rbac/v2/users/${request.userId}/roles`,
    request.data
  );
  return data;
}

export async function updateRolesForMultipleUsersService(
  request
): Promise<UpdateUserRoleLabelResponse> {
  const { data }: AxiosResponse = await identityV2API.put(
    `/rbac/v2/users/assign-roles`,
    request.data
  );
  return data;
}

export async function addRolesForMultipleUsersService(
  request
): Promise<UpdateUserRoleLabelResponse> {
  const { data }: AxiosResponse = await identityV2API.post(
    `/rbac/v2/users/bulk-assign-roles`,
    request.data
  );
  return data;
}

export async function removeBulkUserServiceV2(request) {
  const { data } = await identityV2API.post(
    `/ums/v2/users/bulk-revoke`,
    request.data
  );
  return data;
}
