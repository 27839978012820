import { UnleashClient } from 'unleash-proxy-client';
import axios, { AxiosInstance } from 'axios';
import { SESSION_STORAGE_KEYS } from 'src/lib/constants';
import { fetchOrgDetailByTenantId } from '../controllers/identity/services';

export const unleash = new UnleashClient({
  url: process.env.UNLEASH_PROXY_URL,
  appName: 'copilot-communication',
  clientKey: process.env.UNLEASH_CLIENT_KEY,
});

const DEFAULT_INGRESS_URL: string = process.env.DEFAULT_INGRESS_URL ?? '';

const getTenantId = (): string => {
  return sessionStorage.getItem(SESSION_STORAGE_KEYS.ACCOUNT_ID) ?? '';
};

const getIngressUrlForTenant = async (): Promise<string | undefined> => {
  const orgDetail = JSON.parse(sessionStorage.getItem('orgDetail') ?? '{}') as {
    id?: string;
    ingressUrl?: string;
  };

  if (orgDetail?.ingressUrl) {
    return orgDetail?.ingressUrl;
  }
  const tenantId = getTenantId();
  const data = await fetchOrgDetailByTenantId(tenantId);
  sessionStorage.setItem('orgDetail', JSON.stringify(data));

  return data?.ingressUrl ?? DEFAULT_INGRESS_URL;
};

const proxyApiClient = async (
  unleashFf: string,
  defaultBaseUri?: string
): Promise<AxiosInstance> => {
  await unleash.start();
  unleash.setContextField('userId', getTenantId());
  let baseUrl = defaultBaseUri;
  if (unleash.isEnabled(unleashFf)) {
    baseUrl = await getIngressUrlForTenant();
  }
  const client = axios.create({
    baseURL: baseUrl,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(
        SESSION_STORAGE_KEYS.ACCESS_TOKEN
      )}`,
      'x-fabric-tenant-id': getTenantId(),
    },
  });
  return client;
};

export default proxyApiClient;
