import { AxiosResponse } from 'axios';
import commerceGPTAPI from 'src/services/commerceGPTAPI';
import {
  ChatStreamRequestBody,
  CommerceGPTStarterChipsResponse,
  CommerceGPTResponse,
  CommerceGPTDataObject,
  UserPrompt,
} from '../../../@types';
import determineBackendUrl from 'src/utils/determine-backend-url';

export const sendUserPrompt = async (
  input: UserPrompt
): Promise<CommerceGPTResponse> => {
  const response = await commerceGPTAPI.post('/actions/send', input);
  return response;
};

export const getLeadMessages = async (
  userId: string,
  conversationGroup: CommerceGPTConversationGroupType
): Promise<Array<CommerceGPTDataObject>> => {
  const response = await commerceGPTAPI.get<Array<CommerceGPTDataObject>>(
    '/lead-messages',
    {
      params: {
        userId,
        conversationGroup,
      },
    }
  );
  return response?.data;
};

export const getSuggestedActions = async (): Promise<
  Array<CommerceGPTDataObject>
> => {
  const response = await commerceGPTAPI.get<Array<CommerceGPTDataObject>>(
    '/suggested-actions'
  );
  return response?.data;
};

export const getInsights = async (): Promise<Array<CommerceGPTDataObject>> => {
  const response = await commerceGPTAPI.get<Array<CommerceGPTDataObject>>(
    '/insights'
  );
  return response?.data;
};

export const getSuggestedQuestions = async (): Promise<
  Array<CommerceGPTDataObject>
> => {
  const response = await commerceGPTAPI.get<Array<CommerceGPTDataObject>>(
    '/suggested-questions'
  );
  return response?.data;
};

export const getMessages = async (
  userId: string,
  conversationId: string
): Promise<Array<CommerceGPTDataObject>> => {
  const response = await commerceGPTAPI.get<Array<CommerceGPTDataObject>>(
    '/messages',
    {
      params: {
        userId,
        conversationId,
      },
    }
  );
  return response?.data;
};

export const getPredefinedPrompts = async (): Promise<CommerceGPTStarterChipsResponse> => {
  const response = await commerceGPTAPI.get<CommerceGPTStarterChipsResponse>(
    '/suggested-actions'
  );
  return response.data;
};

export const getChatStream = async (
  requestBody: ChatStreamRequestBody
): Promise<ReadableStream<Uint8Array>> => {
  let baseUrl = process.env.COMMERCE_GPT_API;
  const searchParams = new URLSearchParams(window.location.search);
  const backend = searchParams.get('backend');
  if (backend) {
    baseUrl = determineBackendUrl(backend);
  }

  // Using `fetch` here because Axios doesn't support the text/event-stream content type
  const response = await fetch(`${baseUrl}/actions/send/stream`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'text/event-stream',
      'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
      'x-fabric-tenant-id': sessionStorage.getItem('accountId'),
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  if (!response.body) {
    throw new Error('Response body is null');
  }

  return response.body;
};

export enum CommerceGPTMessageType {
  TEXT = 'TEXT',
  RECOMMENDATION = 'RECOMMENDATION',
  INSIGHT = 'INSIGHT',
  ACTION = 'ACTION',
}

export enum CommerceGPTConversationGroupType {
  HOME = 'Home',
  PRODUCTS = 'Products',
  ORDERS = 'Orders',
  INVENTORY = 'Inventory',
}
