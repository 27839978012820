import { SKUWarning } from '../../../../@types';

const mockedSkuWarningItems: SKUWarning[] = [
  {
    sku: '10001',
    priceListId: 100445,
    productTitle: 'Test Product 01',
    price: 10.0,
    createdAt: new Date(2021, 7, 10),
    startDate: 'No date',
    endDate: 'No date',
    status: 'Not Applicable',
  },
  {
    sku: '10002',
    priceListId: 100445,
    productTitle: 'Test Product 02',
    price: 20.0,
    createdAt: new Date(2021, 7, 16),
    startDate: 'No date',
    endDate: 'No date',
    status: 'Not Applicable',
  },
  {
    sku: '10003',
    priceListId: 100445,
    productTitle: 'Test Product 03',
    price: 30.0,
    createdAt: new Date(2021, 6, 20),
    startDate: 'No date',
    endDate: 'No date',
    status: 'Not Applicable',
  },
  {
    sku: '10004',
    priceListId: 100456,
    productTitle: 'Test Product 04',
    price: 30.0,
    createdAt: new Date(2021, 5, 20),
    startDate: 'No date',
    endDate: 'No date',
    status: 'Not Applicable',
  },
  {
    sku: '10005',
    priceListId: 100456,
    productTitle: 'Test Product 05',
    price: 30.0,
    createdAt: new Date(2021, 4, 20),
    startDate: 'No date',
    endDate: 'No date',
    status: 'Not Applicable',
  },
  {
    sku: '10006',
    priceListId: 100456,
    productTitle: 'Test Product 06',
    price: 30.0,
    createdAt: new Date(2021, 7, 20),
    startDate: 'No date',
    endDate: 'No date',
    status: 'Not Applicable',
  },
  {
    sku: '10007',
    priceListId: 100455,
    productTitle: 'Test Product 07',
    price: 30.0,
    createdAt: new Date(2021, 7, 20),
    startDate: 'No date',
    endDate: 'No date',
    status: 'Not Applicable',
  },
  {
    sku: '10008',
    priceListId: 100455,
    productTitle: 'Test Product 08',
    price: 30.0,
    createdAt: new Date(2021, 7, 20),
    startDate: 'No date',
    endDate: 'No date',
    status: 'Not Applicable',
  },
  {
    sku: '10009',
    priceListId: 100455,
    productTitle: 'Test Product 09',
    price: 30.0,
    createdAt: new Date(2021, 7, 20),
    startDate: 'No date',
    endDate: 'No date',
    status: 'Not Applicable',
  },
];

export default mockedSkuWarningItems;
