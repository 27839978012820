import { BehaviorSubject, of, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';
import {
  PubSubService,
  DataFromChatbot,
  DataToChatbot,
  PublishConfig,
} from './PubSubService';

/**
 * We've created two subjects to handle two distinct data streams:
 * 1. To publish data from chatbot to domain app and
 * 2. To publish data from domain app to chatbot.
 * By separating the streams, each subject handles a different direction of data flow.
 * This prevents data from mixing and makes the service easier to manage, debug, and extend.
 */

class RxJsDataService extends PubSubService {
  private readonly chatbotSubject$: BehaviorSubject<DataFromChatbot | null>;
  private readonly domainSubject$: BehaviorSubject<DataToChatbot | null>;

  constructor() {
    super();
    this.chatbotSubject$ = new BehaviorSubject<DataFromChatbot | null>(null);
    this.domainSubject$ = new BehaviorSubject<DataToChatbot | null>(null);
  }

  publishDataFromChatbot(
    data: DataFromChatbot,
    config: PublishConfig = { log: false }
  ): void {
    of(data)
      .pipe(
        tap((validData) => {
          if (config.log)
            console.debug('data published from the chatbot:', validData);
        })
      )
      .subscribe((validData) => {
        if (this.validateDataFromChatbot(validData)) {
          const messageId = uuidv4();
          this.chatbotSubject$.next({
            data: validData?.data.map((item) => ({ messageId, ...item })),
            suggestionEventId: validData.suggestionEventId
          });
        }
      });
  }

  receiveDataFromChatbot(): Observable<DataFromChatbot> {
    return this.chatbotSubject$.asObservable();
  }

  publishDataToChatbot(
    data: DataToChatbot,
    config: PublishConfig = { log: false }
  ): void {
    of(data)
      .pipe(
        tap((validResponse) => {
          if (config.log)
            console.debug('data published to the chatbot:', validResponse);
        })
      )
      .subscribe((validResponse) => {
        if (this.validateDataToChatbot(validResponse)) {
          this.domainSubject$.next(validResponse);
        }
      });
  }

  receiveDataToChatbot(): Observable<DataToChatbot> {
    return this.domainSubject$.asObservable();
  }

  private validateDataFromChatbot(data: DataFromChatbot): boolean {
    return !isEmpty(data);
  }

  // Helper method to validate ResponseData format
  private validateDataToChatbot(response: DataToChatbot): boolean {
    return !isEmpty(response?.data);
  }
}

// Singleton instance for consumption across micro front ends
export const RxJsService = new RxJsDataService();
