import validate from 'validate.js';

import Joi from 'joi';
import { offersQuantity } from './utils';
import {
  AddSku,
  CreateSkuPriceRequest,
  DeletedPriceList,
  PriceList,
  CreatePriceListRequest,
  GetPriceBySkuRequest,
  GetPriceBySkuResponse,
  CreatePriceRangeResponse,
  UpdatePriceListRequest,
  PriceListSearchResponse,
  GetAddonsResponse,
  CreatePriceListResponse,
  UpdatePriceRangeResponse,
} from '../../../../@types';
import { ISO_DATE_PATTERN } from '../../../lib/index';

const ADDON_SCHEMA = Joi.object({
  priceAddonId: Joi.number().integer().required(),
  name: Joi.string().required(),
  currency: Joi.string().required(),
  price: Joi.number().unsafe().required(),
  createdAt: Joi.string().pattern(ISO_DATE_PATTERN),
  updatedAt: Joi.string().pattern(ISO_DATE_PATTERN),
  deleted: Joi.boolean(),
  _id: Joi.any().strip(),
  __v: Joi.any().strip(),
});

const priceRangeSchema = (addonSchema = ADDON_SCHEMA) => Joi.object({
  itemSku: Joi.string().min(1).required(),
  priceListId: Joi.number().integer().required(),
  itemId: Joi.number().integer(),
  offers: Joi.array().items(
    Joi.object({
      price: Joi.object({
        base: Joi.number().required(),
        sale: Joi.number().allow(null),
        cost: Joi.number().allow(null),
        currency: Joi.string().allow(null),
      }),
      channel: Joi.number().required(),
      startDate: Joi.string().pattern(ISO_DATE_PATTERN),
      endDate: Joi.string().pattern(ISO_DATE_PATTERN),
      kind: Joi.number().integer(),
      priceMethodType: Joi.string().required(),
      createdBy: Joi.string(),
      range: Joi.array().items(
        Joi.object({
          _id: Joi.string(),
          minQuantity: Joi.number().required(),
          maxQuantity: offersQuantity.required(),
          price: Joi.number().allow(null),
          width: Joi.array().items({
            minQuantity: Joi.number().required(),
            maxQuantity: offersQuantity.required(),
            price: Joi.number().required(),
          }),
        }),
      ),
      priceAttributes: Joi.array().items(
        addonSchema,
      ).required(),
      createdAt: Joi.string().pattern(ISO_DATE_PATTERN),
      updatedAt: Joi.string().pattern(ISO_DATE_PATTERN),
      isSoftDeleted: Joi.boolean(),
      job: Joi.boolean(),
      offerId: Joi.number().integer(),
    }),
  ).required(),
  offerId: Joi.number().integer(),
  createdAt: Joi.string().pattern(ISO_DATE_PATTERN),
  updatedAt: Joi.string().pattern(ISO_DATE_PATTERN),
  __v: Joi.any().strip(),
});

export function validateSkuPriceResponse(apiResponse: CreateSkuPriceRequest[]) {
  validate.isArray(apiResponse);
  apiResponse.forEach((item) => {
    const baseError = validate(item, {
      priceList: { type: 'array', presence: { allowEmpty: false } },
      sku: { type: 'array' },
      startDate: { type: 'string' },
      endDate: { type: 'string' },
      base: { type: 'number' },
      priceMethodType: { type: 'string' },
      channel: { type: 'array' },
      priceParams: { type: 'array' },
      priceAttributes: { type: 'array' },
    });
    if (baseError) {
      throw baseError;
    }
  });
  return true;
}

export function validateFetchAllPriceListsResponse(apiResponse: PriceList[]) {
  const objectSchema = Joi.object({
    isDefault: Joi.boolean().required(),
    deleted: Joi.boolean().required(),
    priceListId: Joi.number().required(),
    name: Joi.string().required(),
    currency: Joi.string().required(),
    startDate: Joi.string().allow(null),
    endDate: Joi.string().allow(null),
    createdAt: Joi.string().required(),
    updatedAt: Joi.string().required(),
    __v: Joi.number(),
    _id: Joi.string(),
  });
  const arraySchema = Joi.array().items(objectSchema);

  const { error } = arraySchema.validate(apiResponse, { abortEarly: false });

  if (error) {
    throw error;
  }
  apiResponse.forEach((item) => {
    delete item.__v;
    delete item._id;
  });
  return true;
}
export function validateDeletePriceListResponse(apiResponse: DeletedPriceList) {
  const objectSchema = Joi.object({
    createdAt: Joi.string().required(),
    priceListId: Joi.number().required(),
    name: Joi.string().required(),
    isDefault: Joi.boolean().required(),
    currency: Joi.string().required(),
    updatedAt: Joi.string().required(),
    __v: Joi.number().required(),
    _id: Joi.string().required(),
    deleted: Joi.boolean().required(),
    startDate: Joi.string().allow(null),
    endDate: Joi.string().allow(null),
  });

  const { error } = objectSchema.validate(apiResponse, { abortEarly: false });

  if (error) {
    throw error;
  }
  return true;
}

export function validateAddSkuResponse(apiResponse: AddSku) {
  const objectSchema = Joi.object({
    deleted: Joi.boolean().required(),
    _id: Joi.string().required(),
    sku: Joi.string().required(),
    createdAt: Joi.string().required(),
    updatedAt: Joi.string().required(),
  });

  const { error } = objectSchema.validate(apiResponse, { abortEarly: false });

  if (error) {
    throw error;
  }
  return true;
}

export function validateCreatePriceList(request: CreatePriceListRequest) {
  const objectSchema = Joi.object({
    name: Joi.string().required(),
    isDefault: Joi.boolean().required(),
    currency: Joi.string().required(),
    startDate: Joi.string().optional(),
    endDate: Joi.string().optional(),
    deleted: Joi.boolean().optional(),
  });

  const { error } = objectSchema.validate(request, { abortEarly: false });

  if (error) {
    throw error;
  }
  return true;
}

export function validateCreatePriceListResponse(response: CreatePriceListResponse) {
  const objectSchema = Joi.object({
    isDefault: Joi.boolean().required(),
    deleted: Joi.boolean().required(),
    _id: Joi.string().required(),
    name: Joi.string().required(),
    currency: Joi.string().required(),
    startDate: Joi.string(),
    endDate: Joi.string(),
    priceListId: Joi.number().required(),
    createdAt: Joi.string().required(),
    updatedAt: Joi.string().required(),
  });

  const { error } = objectSchema.validate(response, { abortEarly: false });

  if (error) {
    throw error;
  }
  return true;
}

export function validateUpdatePriceList(request: UpdatePriceListRequest) {
  validate.isObject(request);
  const baseError = validate(request, {
    name: { type: 'string', presence: { allowEmpty: false } },
    currency: { type: 'string', presence: { allowEmpty: false } },
    isDefault: { type: 'boolean', presence: { allowEmpty: false } },
    startDate: { type: 'string' },
    endDate: { type: 'string' },
    deleted: { type: 'boolean', presence: { allowEmpty: false } },
  });

  if (baseError) {
    throw baseError;
  }
  return true;
}

export function validatePriceListSearch(apiResponse: PriceListSearchResponse) {
  const objectSchema = Joi.object({
    query: Joi.object({
      limit: Joi.number(),
      count: Joi.number(),
      offset: Joi.number(),
      sortBy: Joi.string().optional(),
      sortOrder: Joi.string().optional(),
    }),
    results: Joi.array().items(
      Joi.object({
        sku: Joi.string().required(),
        itemId: Joi.number().allow(null),
        priceStartDate: Joi.string().allow(null),
        priceEndDate: Joi.string().allow(null),
        basePrice: Joi.number().allow(null),
        salePrice: Joi.number().allow(null),
        priceMethodType: Joi.string().allow(null),
        channel: Joi.number().allow(null),
        status: Joi.string().allow(null),
        createdBy: Joi.string().allow(null, ''),
        createdAt: Joi.string().allow(null),
        updatedAt: Joi.string().allow(null),
        priceRange: Joi.object({
          min: Joi.number().allow(null),
          max: Joi.number().allow(null),
        }).allow(null),
        title: Joi.string().allow(null, ''),
        images: Joi.array().items(),
        priceListId: Joi.number().required(),
        __v: Joi.number().optional(),
        _id: Joi.string().optional(),
        priceListStartDate: Joi.string().allow(null),
        priceListEndDate: Joi.string().allow(null),
      }),
    ),
  });

  const { error: resultError } = objectSchema.validate(
    apiResponse,
    { abortEarly: false, allowUnknown: true },
  );

  let dataError;
  if (apiResponse.query.count > 0 && apiResponse.results.length <= 0) {
    dataError = {
      count: ['Data is inconsistent with the count result'],
    };
  }

  const baseError = {
    ...resultError,
    ...dataError,
  };

  if (baseError && JSON.stringify(baseError) !== '{}') {
    // eslint-disable-next-line no-throw-literal
    throw {
      status: 'VALIDATION_ERROR',
      message: baseError,
    };
  }
  return true;
}

export function validateGetPriceBySkuResponse(
  priceRangeResponse: GetPriceBySkuResponse,
): GetPriceBySkuResponse {
  const objectSchema = priceRangeSchema();

  const { error, value } = objectSchema.validate(priceRangeResponse, {
    abortEarly: false, allowUnknown: true,
  });

  if (error) {
    throw error;
  }

  return value;
}

export function validateGetPriceBySkuRequest(apiRequest: GetPriceBySkuRequest) {
  validate.isObject(apiRequest);
  const baseError = validate(apiRequest, {
    sku: { type: 'string', presence: { allowEmpty: false } },
    priceListId: { type: 'number' },
  });

  if (baseError) {
    throw baseError;
  }
  return true;
}

export function validateCreatePriceResponse(
  createPriceRangeResponse: CreatePriceRangeResponse,
): CreatePriceRangeResponse {
  const priceAttributesSchema = Joi.object({
    attribute: Joi.string().required(),
    value: Joi.number().required(),
  });
  const objectSchema = Joi.array().items(
    priceRangeSchema(priceAttributesSchema),
  );

  const { error, value } = objectSchema.validate(createPriceRangeResponse, {
    abortEarly: false,
    allowUnknown: true,
  });

  if (error) {
    throw error;
  }

  return value;
}

export function validateUpdatePriceResponse(
  updatePriceRangeResponse: UpdatePriceRangeResponse,
): UpdatePriceRangeResponse {
  const priceAttributesSchema = Joi.object({
    attribute: Joi.string().required(),
    value: Joi.number().required(),
  });
  const objectSchema = priceRangeSchema(priceAttributesSchema);

  const { error, value } = objectSchema.validate(updatePriceRangeResponse, {
    abortEarly: false,
    allowUnknown: true,
  });

  if (error) {
    throw error;
  }

  return value;
}

export function validateCurrencyCodes(codes) {
  let baseError = {};
  codes.forEach((code) => {
    const codeError = validate(code, {
      _id: { type: 'string', presence: true },
      isActive: { type: 'boolean', presence: true },
      name: { type: 'string', presence: true },
      channelCode: { type: 'string', presence: true },
      kind: { type: 'string', presence: true },
      region: { type: 'string', presence: true },
      channelId: { type: 'number', presence: true },
      address: { type: 'array', presence: { allowEmpty: true } },
      createdAt: { type: 'string', presence: true },
      updatedAt: { type: 'string', presence: true },
      currencyCode: { type: 'string', presence: true },
    });
    if (codeError) {
      baseError = { ...baseError, ...codeError };
    }
  });
  if (baseError && JSON.stringify(baseError) !== JSON.stringify({})) {
    // eslint-disable-next-line no-throw-literal
    throw {
      status: 'VALIDATION_ERROR',
      message: baseError,
    };
  }
  return true;
}

export function validateGetAddonsResponse(getAddonsResponse: GetAddonsResponse): GetAddonsResponse {
  const objectSchema = Joi.object({
    query: Joi.object({
      limit: Joi.number().required(),
      count: Joi.number().required(),
      offset: Joi.number().required(),
    }).required(),
    data: Joi.array().items(
      ADDON_SCHEMA,
    ).required(),
  });

  const { error, value } = objectSchema.validate(getAddonsResponse, {
    abortEarly: false,
    allowUnknown: true,
  });

  if (error) {
    throw error;
  }

  return value;
}
