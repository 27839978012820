import { navigateToUrl } from 'single-spa';

export function toOMS(path: string): void {
  navigateToUrl(`/oms/${path}`);
}

export function toOffers(path: string): void {
  navigateToUrl(`/offers/${path}`);
}

export function toXM(path: string): void {
  navigateToUrl(`/xpm/${path}`);
}

export function toPIM(path: string): void {
  navigateToUrl(`/pim/${path}`);
}

export function toAdmin(path?: string): void {
  navigateToUrl(`/admin/${path}`);
}

export function toSubscriptions(path?: string): void {
  navigateToUrl(`/smt/${path ?? ''}`);
}
