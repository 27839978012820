import { BehaviorSubject } from 'rxjs';

enum ChatExpansionStateType {
  FULL_SCREEN = 'full-screen',
  THIRD = 'third-screen',
  COLLAPSED = 'collapsed',
}

const chatExpansionState$ =
  new BehaviorSubject<ChatExpansionStateType>(ChatExpansionStateType.COLLAPSED);

const fullyOpenFabAIChat = (): void => {
  chatExpansionState$.next(ChatExpansionStateType.FULL_SCREEN);
};

const fullyCollapseFabAIChat = (): void => {
  chatExpansionState$.next(ChatExpansionStateType.COLLAPSED);
};

const shrinkFabAIChat = (): void => {
  chatExpansionState$.next(ChatExpansionStateType.THIRD);
};

/* switches between full screen and 1/3 screen view */
const toggleExpansionState = (): void => {
  if (chatExpansionState$.value === ChatExpansionStateType.FULL_SCREEN) {
    chatExpansionState$.next(ChatExpansionStateType.THIRD);
  } else if (chatExpansionState$.value === ChatExpansionStateType.THIRD) {
    chatExpansionState$.next(ChatExpansionStateType.FULL_SCREEN);
  }
};

/* switches between full screen and 1/3 screen view */
const toggleChatVisibility = (): void => {
  if (chatExpansionState$.value === ChatExpansionStateType.COLLAPSED) {
    chatExpansionState$.next(ChatExpansionStateType.THIRD);
  } else {
    chatExpansionState$.next(ChatExpansionStateType.COLLAPSED);
  }
};

export {
  ChatExpansionStateType,
  chatExpansionState$,
  fullyOpenFabAIChat,
  fullyCollapseFabAIChat,
  shrinkFabAIChat,
  toggleExpansionState,
  toggleChatVisibility,
};
